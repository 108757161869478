import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdministrativoService } from '../services/administrativo.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.css']
})
export class CadastrarComponent implements OnInit {
  formularioCadastrarUsuario: FormGroup;
  carregandoCadastro: boolean = true;
  visivel: boolean = false;
  nivelAcesso;

  ngOnInit() {
    this.formularioCadastrarUsuario = this.formBuilder.group(
      {
        email: ['', Validators.required],
        idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
        login: ['', Validators.required],
        matricula: ['', Validators.required],
        nivelAcesso: ['',Validators.required],
        nome: ['', Validators.required],
        senha: ['', Validators.required],
      }
    );

    this.UsuariosService.listarNiveisAcesso().subscribe(
      res =>{
        this.carregandoCadastro = false;
        this.nivelAcesso = res;
      },
      err => {
        this.carregandoCadastro = false;
        this.dialogRef.close();
        this.snackBarNotification(err.error.message, 'erro');
      }
    )
  }

  onSubmit() {
    if (this.formularioCadastrarUsuario.valid) {
      this.cadastrarProcesso(this.formularioCadastrarUsuario.value);
    }
  }

  constructor(
    private dialogRef: MatDialogRef<CadastrarComponent>,
    private formBuilder: FormBuilder,
    private UsuariosService: AdministrativoService,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) { }

  closeModal() {
    this.dialogRef.close();
  }

  cadastrarProcesso(usuario) {
    this.carregandoCadastro = true;
    console.log(usuario)
    this.autenticacao.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){

        this.UsuariosService.cadastrarUsuario(usuario)
        .subscribe(
          res => {
            this.formularioCadastrarUsuario.reset();
            this.carregandoCadastro = false;
            this.snackBarNotification('Usuário cadastrado com sucesso!','sucesso');
            console.log(usuario);
            this.dialogRef.close(true);
          },
          err => {
            this.carregandoCadastro = false;
            this.snackBarNotification(err.error.message, 'erro');
          }
        );

      } else {
        this.carregandoCadastro = false;
      }
    })
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
