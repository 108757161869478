import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { Observable, observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProcessoDetalhadoService {
  idUsuario: number;
  idModulo: number;
  usuarioData: UsuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idUsuario = this.usuarioData.idUsuario;
    this.idModulo = this.usuarioData.modulo.id;
  }

  listarEventos(idProcesso: number) {
    return this.http.get(`${environment.caminhoApi}eventos?idProcesso=${idProcesso}`, httpOptions);
  }

  listarArquivos(idProcesso: number) {
    return this.http.get(`${environment.caminhoApi}arquivos-processo/no-link?idProcesso=${idProcesso}&idUsuario=${this.idUsuario}&idModulo=${this.idModulo}`, httpOptions);
  }

  arquivosUnidos(idProcesso: number) {
    return this.http.get(`${environment.caminhoApi}arquivosUnidosBase64?idProcesso=${idProcesso}`, httpOptions);
  }

  detalharProcesso(idProcesso: number) {
    console.log(JSON.parse(localStorage.getItem('usuarioData')).idUsuario)
    return this.http.get(`${environment.caminhoApi}detalhar-processo?idProcesso=${idProcesso}&idUsuario=${JSON.parse(localStorage.getItem('usuarioData')).idUsuario}`, httpOptions);
  }

  acoesProcesso(idProcesso: number) {
    return this.http.get(`${environment.apiAdmin}acoes-usuario?idProcesso=${idProcesso}&idUsuarioLogado=${JSON.parse(localStorage.getItem('usuarioData')).idUsuario}&idSistema=2`, httpOptions);
  }

  arquivoBase64(idArquivo: number) {
    return this.http.get(`${environment.caminhoApi}arquivosBase64PorProcesso/{idArquivo}?idArquivo=${idArquivo}`, httpOptions);
  }

  ordernarArquivos(arquivosOrdenados, idProcesso: number) {
    return this.http.put(`${environment.caminhoApi}ordenacao/?idProcesso=${idProcesso}`, arquivosOrdenados, httpOptions);
  }

  dadosRetificacao(idProcesso) {
    return this.http.get(`${environment.caminhoApi}retificar-processo?idProcesso=${idProcesso}`, httpOptions);
  }

  retificarDadosProcesso(dadosRetificacao) {
    return this.http.post(`${environment.caminhoApi}retificar-processo`, dadosRetificacao, httpOptions);
  }

  listarVotos(idProcesso) {
    return this.http.get(`${environment.caminhoApi}votar?idProcesso=${idProcesso}`, httpOptions);
  }

  criarVoto(voto) {
    return this.http.put(`${environment.caminhoApi}votar`, voto, httpOptions);
  }

}
