import { Component, OnInit } from '@angular/core';
import { PainelService } from './services/painel.service';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.css']
})
export class PainelComponent implements OnInit {
  usuarioData: UsuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  loading = true;

  indicadoresGeral;

  constructor(
    private painelService: PainelService,
    ) { }

  ngOnInit() {
    this.painelService.indicadores().subscribe(
      res=>{
        this.indicadoresGeral = res;
        this.loading = false;
        console.log(this.indicadoresGeral);
      },
      error =>{
        this.loading = false;
      }
    )
  }
}
