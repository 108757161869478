import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};
@Injectable({
  providedIn: 'root'
})
export class PainelService {
  usuarioData: UsuarioData = JSON.parse(localStorage.getItem('usuarioData'));

  constructor(
    public http: HttpClient,
    ) { }

  indicadores(): Observable<any> {
    return this.http.get<any>(`${environment.caminhoApi}indicadores`, httpOptions);
  }

}
