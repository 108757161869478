export const environment = {
  production: true,  
  apiAdmin: 'https://hmg.admin.sgi.sigtrans.com.br/v1/',
  caminhoApi: 'https://hmg.api.sgi.sigtrans.com.br/v1/',
  apiTalonario: 'http://hmg.api.talonario.sigtrans.com.br:8090/api/v1/',
  //apiAdmin: 'https://api.adm.detranpa.sigtrans.com.br/v1/',
  //caminhoApi: 'https://api.sgi.detranpa.sigtrans.com.br/v1/',
  //apiTalonario: 'http://api.talonario.sigdetranpa.com.br:8090/api/v1/',
  //ambiente: 'DetranPA v3',
  ambiente: 'DetranPA v3 homolog | © 2021, Aureaphigital todos os direitos reservados',  
  logoProduto: '/assets/images/logo-sgi-nome-branco.png',
  logoCliente: '/assets/images/logo_detran_pa.png'
};