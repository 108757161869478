import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NomeTituloService } from './shared/services/nometitulo.service';
import { LoginService } from 'src/app/modules/login/service/login-provider'
import { AtualizarSenhaService } from './shared/services/atualizar-senha.service';
import { ManualService } from 'src/app/shared/services/manual.service';
import { AdministrativoService } from 'src/app/shared/services/administrativo.service';
import {
  enable as ativaDarkMode,
  disable as desabilitaDarkMode,
  setFetchMethod,
} from 'darkreader';
import { VersionService } from './shared/services/version.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  hoverDashboard: boolean;
  hoverVincular: boolean;
  VincularAit: boolean;
  hoverProcessos: boolean;
  hoverConsultarProcesso: boolean;
  hoverDocumentTemplate: boolean;
  clicado: boolean;
  nomePagina: string;
  nomeSecao: string;
  usuarioLogado: boolean = true;
  usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  corSetor:string = '#1f1f1f';
  darkMode: boolean = false;

  nomeUsuario: string;
  matriculaUsuario: string;

  version = this.versionService.version;
  ambiente = environment.ambiente;

  constructor(
    private router: Router,
    private nomeTituloService: NomeTituloService,
    public atualizarSenhaService: AtualizarSenhaService,
    public manual: ManualService,
    public administrativo: AdministrativoService,
    private versionService: VersionService,
  ) {

    this.nomeTituloService.path.subscribe(({ nomeSecao }) => {
      this.nomeSecao = nomeSecao;
      // this.corSetor = this.usuarioData.modulo.color;
    });

  }

  mostrarFuncionalidades(funcionalidade) {
    if (this.usuarioData) {
      return this.usuarioData.modulo.funcionalidades.find(f => f.identificador === funcionalidade);
    }
  }

  ngOnInit() {
    setFetchMethod(window.fetch);

    if (JSON.parse(localStorage.getItem('darkMode'))) {
      this.darkModeState();
    }

    if (this.usuarioData !== null) {
      this.usuarioLogado = true;
      this.corSetor = this.usuarioData.modulo.color;
      // this.router.navigate(['painel']);
    } else {
      this.usuarioLogado = false;
      this.router.navigate(['login']);
    }

    LoginService.usuarioLogado
      .subscribe(estado => {
        if (estado) {
          this.usuarioLogado = true;
          this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
          this.corSetor = this.usuarioData.modulo.color;
          // this.router.navigate(['painel']);
        } else {
          this.usuarioLogado = false;
          this.router.navigate(['login']);
        }
      })

  }

  realizarLogoff() {
    localStorage.removeItem('usuarioData');
    this.usuarioLogado = false;
    this.router.navigate(['login'])
  }

  atualizarSenha() {
    this.atualizarSenhaService.abrirDialogo()
  }

  darkModeState(){
    this.darkMode = !this.darkMode;
    const darkModeState = JSON.stringify(this.darkMode);
    if(this.darkMode) {
      ativaDarkMode({
        brightness: 100,
        contrast: 100,
        sepia: 0,
      });
      localStorage.setItem('darkMode', darkModeState);
    } else {
      desabilitaDarkMode();
      localStorage.setItem('darkMode', darkModeState);
    }
  }

  abrirManual(){
    this.manual.abrirDialogo();
  }

  abrirAdministrativo(){
    this.administrativo.abrirDialogo();
  }

}
