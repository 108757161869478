import { JuntarProcessoService } from './../../shared/services/juntar-processo.service';
import { Component, ViewChild } from "@angular/core";
import { ListaProcessosService } from "./services/lista-processos.service";
import { ProcessoSelecionado } from "src/app/shared/models/processo-selecionado.model";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MovimentarProcessoService } from 'src/app/shared/services/movimentar-processo.service';
import { ProcessoDetalhadoService } from 'src/app/shared/services/processo-detalhado.service';
import { MarcadorProcessoService } from 'src/app/shared/services/marcar-processo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ListaProcessosSelecionadosComponent } from './lista-processos-selecionados/lista-processos-selecionados.component';
import { BodyFiltro } from 'src/app/shared/models/filtro.model';
import { JuntarProcessoComponent } from 'src/app/shared/components/juntar-processo/juntar-processo.component';
import { DistribuirProcessoService } from "src/app/shared/services/distribuir-processo.service";
import { SelectionModel } from "@angular/cdk/collections";
import { animate, state, style, transition, trigger } from "@angular/animations";


@Component({
  selector: "app-lista-processos",
  templateUrl: "./lista-processos.component.html",
  styleUrls: ["./lista-processos.component.css", "../../shared/styles/material-table-responsive.css"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', backgroundColor: 'white', borderBottomColor: 'white'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListaProcessosComponent {

  expandedElement: any | null;

  @ViewChild(MatPaginator, { static: false }) paginacao: MatPaginator;
  selection = new SelectionModel<any>(true, []);

  usuarioData = JSON.parse(localStorage.getItem("usuarioData"));
  dataInicio = new FormControl(new Date());
  dataFim = new FormControl(new Date());
  filtroVisivel: boolean = true;
  pipe = new DatePipe("en-US");
  // pageSize = 10;
  // pageIndex = 1;
  totalProcessosConsulta = 0;
  bodyFiltro: BodyFiltro = {
    from: null,
    idModuloAtual: JSON.parse(localStorage.getItem("usuarioData")).modulo.id,
    idUsuarioAlocado: null,
    idUsuarioLogado: JSON.parse(localStorage.getItem("usuarioData")).idUsuario,
    nomeRequerente: null,
    numAit: null,
    numPlaca: null,
    numProtocolo: null,
    pag: 1,
    qtd: 10,
    origem: null,
    status: null,
    tipo: null,
    to: null,
  };
  todasDatas: boolean = true;
  statusSelecionado = null;
  tipoSelecionado = null;
  setorSelecionado = null;
  usuarioSelecionado = null;
  estagiario =
    JSON.parse(localStorage.getItem("usuarioData")).tipoUsuario ===
    "ESTAGIARIO"; /** regra para estagiario **/

  mostrarAcoes: boolean = true;
  carregandoGrid = false;
  input = "";
  filtro = "";
  listStatus = [
    { value: null, nome: "Todos os status" },
    { value: "0", nome: "Aberto" },
    { value: "1", nome: "Andamento" },
    // {value: "2", nome: "Arquivado"},
    // {value: "3", nome: "Deferido"},
    // {value: "4", nome: "Indeferido"},
    // {value: "5", nome: "Cancelado"},
    // {value: "6", nome: "Suspenso"},
    // {value: "7", nome: "Descartado"},
  ];
  listTipos = [
    { value: null, nome: "Todos os tipos" },
    { value: "0", nome: "Defesa de Autuação" },
    { value: "1", nome: "Recurso" },
    // {value: "2", nome: "Comunicação de Venda"},
    // {value: "3", nome: "Transferência de Pontuação"},
  ];
  listSetores = [
    { value: null, nome: "Todos os Setores" },
    { value: "1", nome: "Protocolo" },
    { value: "2", nome: "Renainf" },
    { value: "3", nome: "Projur" },
    { value: "4", nome: "Jari" },
    { value: "9999", nome: "Portal Cidadão" },
  ];
  listModulos = [
    {
      value: this.usuarioData.modulo.id,
      nome: this.usuarioData.modulo.nome,
    },
  ];
  listUsuarios = [];
  meusProcessos = false;
  processosSelecionados: Array<ProcessoSelecionado> = [];
  displayedColumns: string[] = [
    "select",
    "protocolo",
    // "ait",
    // "placa",
    // "espelhoAssinado",
    // "condutor",
    "interessado",
    // "documento",
    "tipo",
    "dataAbertura",
    "situacao",
    "origem",
    "alocado",
    "detalhar",
  ];
  gridData = new MatTableDataSource();

  constructor(
    private notificacao: MatSnackBar,
    public movimentarProcesso: MovimentarProcessoService,
    public juntarProcesso: JuntarProcessoService,
    private processosService: ListaProcessosService,
    private detalharProcesso: ProcessoDetalhadoService,
    private MarcadorProcessoService: MarcadorProcessoService,
    public DistribuirProcessoService: DistribuirProcessoService,
    private dialog: MatDialog
  ) { }

  /** Detalhar Processo */
  detalharProcessoSelecionado(idProcesso) {
    this.detalharProcesso
      .abrirDialogo(idProcesso, this.mostrarAcoes)
      .subscribe((res) => {
        if (res) {
          this.listarProcessos();
        }
      });
  }

  ngOnInit() {
    if (this.estagiario) {
      /** regra para estagiario **/
      this.meusProcessos = true;
      this.bodyFiltro.idUsuarioAlocado = this.usuarioData.idUsuario;
    }
    ListaProcessosService.atualizarListaDeProcessos.subscribe((value) => {
      if (value) {
        this.listarProcessos();
        this.processosSelecionados = [];
      }
    });
    this.dataInicio.value.setDate(this.dataInicio.value.getDate() - 30);
    this.listarProcessos();
    this.processosService.listarUsuariosBusca().subscribe((res: Array<any>) => {
      this.listUsuarios = res.map((item) => {
        return { value: item.id, nome: item.nome };
      });
      this.listUsuarios.unshift({ value: 1, nome: "Todos os Usuarios" });
      this.listUsuarios.unshift({ value: 0, nome: "Não Alocado" });
    });
  }

  botaoClickadoEvent(event) {
    switch (event.tipo) {
      case "juntar-processo": {
        this.juntarProcesso.abrirDialogo(this.processosSelecionados)
        .subscribe(res => {  this.listarProcessos() });
      }
      break;
      case "movimentar-processo":
        {
          this.movimentarProcesso
            .abrirDialogo(this.processosSelecionados)
            .subscribe((res) => {
              if (res) this.listarProcessos();
            });
        }
        break;
      case "marcar-processo":
        {
          this.MarcadorProcessoService.abrirDialogo(
            this.processosSelecionados
          ).subscribe((res) => {
            if (res) this.listarProcessos();
          });
        }
        break;
      case "distribuir-processo":
        {
          this.DistribuirProcessoService.abrirDialogo(
            this.processosSelecionados
          ).subscribe((res) => {
            this.listarProcessos();
          });
        }
        break;
    }
  }

  mostrarBotao(funcionalidade) {
    return this.usuarioData.modulo.funcionalidades.find(
      (f) => f.identificador === funcionalidade
    );
  }

  listarProcessos() {
    this.carregandoGrid = true;

    if (this.bodyFiltro.nomeRequerente === "") {
      this.bodyFiltro.nomeRequerente = null;
    }
    if (this.bodyFiltro.numAit === "") {
      this.bodyFiltro.numAit = null;
    }
    if (this.bodyFiltro.numPlaca === "") {
      this.bodyFiltro.numPlaca = null;
    }
    if (this.todasDatas) {
      this.bodyFiltro.from = null;
      this.bodyFiltro.to = null;
    } else {
      this.bodyFiltro.from = this.transformarData(this.dataInicio.value);
      this.bodyFiltro.to = this.transformarData(this.dataFim.value);
    }

    if (this.bodyFiltro.numProtocolo === "") {
      this.bodyFiltro.numProtocolo = null;
    }

    this.processosService.filtro(this.bodyFiltro).subscribe(
      (res: any) => {
        this.carregandoGrid = false;
        this.gridData.data = res.itens;
        this.totalProcessosConsulta = res.qtd;
        console.log(res);
      },
      (err) => {
        this.carregandoGrid = false;
        this.gridData.data = [];
        this.totalProcessosConsulta = 0;
        if (err.status === 404) {
          this.snackBarNotification(
            "Nenhum processo encontrado na pesquisa!",
            "erro"
          );
        }
      }
    );
  }

  doFilter(value: string) {
    this.gridData.filter = value.trim().toLocaleLowerCase();
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, "", {
      duration: 4000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: [tipo],
    });
  }

  transformarData(data: Date) {
    return this.pipe.transform(data, "yyyy-MM-dd");
  }

  pageEvent(event) {
    this.bodyFiltro.pag = event.pageIndex + 1;
    this.bodyFiltro.qtd = event.pageSize;

    //Limpar o Select
    this.selection.clear()
    this.gridData.data.forEach((row) => {
      this.unchecked(row);
    })

    this.listarProcessos();
  }

  novaBusca() {
    this.paginacao.firstPage();
    this.bodyFiltro.pag = 1;
    this.totalProcessosConsulta = 0;
    this.listarProcessos();


  }

  visulizacaoProcessos(event) {
    if (event.checked) {
      // this.todasDatas = true;
      this.bodyFiltro.idUsuarioAlocado = this.usuarioData.idUsuario;
      this.novaBusca();
    } else {
      // this.todasDatas = false;
      this.bodyFiltro.idUsuarioAlocado = null;
      this.novaBusca();
    }
  }

  ifModulo(event) {
    console.log(event.value === "alocado_modulo");
    if (event.value === "alocado_modulo")
      this.input = this.usuarioData.modulo.id;
  }

  isChecked(row) {
    return this.processosSelecionados.find(
      (processo) => processo.id === row.id
    ) === undefined
      ? false
      : true;
  }

  checked(row) {
    this.processosSelecionados.push(row);
  }

  unchecked(row) {
    this.processosSelecionados = this.processosSelecionados.filter(
      (processo) => processo.id !== row.id
    );
  }

  abrirListaDeProcessosSelecionados() {
    const dialogRef = this.dialog.open(ListaProcessosSelecionadosComponent, {
      data: this.processosSelecionados,
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.processosSelecionados = result;
    });
  }

  sizeTop() {
    return window.innerWidth > 767 ? 60 : 120;
  }

  atualizarValorSelecao(event, matriz) {
    return matriz.find((item) => item.value === event.value).nome;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.gridData.data.length;


    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear()
      this.gridData.data.forEach((row) => {
        this.unchecked(row);
      })
    } else {
      this.gridData.data.forEach((row) => {
        this.selection.select(row);
        this.checked(row);
      })
    }
  }

}
