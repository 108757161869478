import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { VincularAitService } from '../services/vincular-ait.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-selecionar-ait',
  templateUrl: './selecionar-ait.component.html',
  styleUrls: ['./selecionar-ait.component.css']
})
export class SelecionarAitComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private listarAitsTalonario: VincularAitService,
    public dialogRef: MatDialogRef<SelecionarAitComponent>,
  ) { }

  displayedColumns: string[] = [
    'select',
    'numero',
    'placa',
    'tipificacao',
    'nomeCondutor',
    'documentoCondutor',
    'dthOcorrencia',
    // 'tipoDocumentoCondutor'
  ];

  aits = new MatTableDataSource();
  // aits = [{
  //   enumOrigemAit: 1,
  //   numero: "TL00270827",
  //   placa: "QDB5960",
  //   dataAutuacao: "2020-03-29T21:30:00",
  //   tipificacao: "(Art. 244, II) - CONDUZIR MOT/MOTON/CICLOMOT.TRANSPORTANDO PASSAG S/ CAPACETE",
  //   nomeCondutor: null,
  //   enumTipoDocumentoCondutor: null,
  //   documentoCondutor: null,
  //   dthOcorrencia: "29/03/2020 21:30",
  //   tipoDocumentoCondutor: "CNH",
  // }];
  dataInicio = new FormControl(new Date());
  dataFim = new FormControl(new Date());
  take = 10;
  skip = 0;
  totalAitsConsulta = 0;
  isLoadingResults = false;
  selection = new SelectionModel(false);


  pipe = new DatePipe('en-US');

  ngOnInit() {
  }

  transformarData(data: Date) {
    return this.pipe.transform(data, 'dd/MM/yyyy')
  }

  buscarAits() {

    this.paginator.pageIndex = 0;
    this.isLoadingResults = true;
    this.selection.clear() 
    this.listarAitsTalonario.listarAits(
      this.transformarData(this.dataInicio.value),
      this.transformarData(this.dataFim.value),
      0,
      this.take
    )
      .subscribe((res: any) => {
        if (res !== null) {
          this.isLoadingResults = false;
          this.aits.data = res.aits;
          this.totalAitsConsulta = res.total;
        } else {
          this.isLoadingResults = false;
          this.aits.data = null;
        }
        console.log(res);
      })

  }

  pageEvent(event) {
    console.log(event);
    this.isLoadingResults = true;
    this.selection.clear() 
    this.listarAitsTalonario.listarAits(
      this.transformarData(this.dataInicio.value),
      this.transformarData(this.dataFim.value),
      event.pageIndex * this.take,
      this.take
    )
      .subscribe((res: any) => {
        this.isLoadingResults = false;
        this.aits.data = res.aits;
        this.totalAitsConsulta = res.total;
        console.log(res);
      })
  }

  aitSelecionada(){
    this.dialogRef.close(this.selection.selected[0])
    // console.log(this.selection.selected[0]);
  }


}
