import { Component, ElementRef, Renderer2, Inject, NgZone, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentTemplate } from '../model/document-template.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { DocumentTemplateService } from '../service/document-template.service';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as htmlToPdfMake from 'html-to-pdfmake';
import { environment } from 'src/environments/environment';
import { DocumentTemplateConfig } from '../model/document-template-config.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import Quill from 'quill';

@Component({
  selector: 'app-document-template-form',
  templateUrl: './document-template-form.component.html',
  styleUrls: ['./document-template-form.component.css']
})
export class DocumentTemplateFormComponent implements OnInit, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('pdfContainer') pdfContainer: ElementRef;
  @ViewChild('template') editorElement: ElementRef;


  pdfUrl = '';


  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      // ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  public modulesQuillHeaderFooter = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      // ['blockquote', 'code-block'],
      // [{ list: 'ordered' }, { list: 'bullet' }],
      // ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  public htmlContent: any;
  public htmlContentTemplate: any;
  public htmlContentHeader: any;
  public htmlContentFooter: any;
  public htmlContentWaterMark: any;

  usuarioData: UsuarioData;
  carregandoCadastro: boolean = false;
  documentTemplateForm: FormGroup;
  documentTemplate: DocumentTemplate;

  textoSubmit = 'Salvar';

  documentConfig: DocumentTemplateConfig;

  checkboxChecked = false;

  logoCliente = environment.logoCliente;

  exibirDiv: boolean = false;


  // Variáveis relacionadas à sugestão de variáveis
  private quill: Quill;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public documentTemplateEditar: DocumentTemplate,
    private dialogRef: MatDialogRef<DocumentTemplateFormComponent>,
    private fb: FormBuilder,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private documentTemplateService: DocumentTemplateService,
    private renderer: Renderer2,
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  }

  // Assine o evento 'text-change' do Quill Editor
  private subscribeTextChangeEvent(): void {
    this.quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
      if (source === 'user') {
        const content = this.quill.root.innerHTML;
        this.onChangedEditor({ html: content });
      }
    });
  }

  ngOnInit(): void {
    this.carregarConfig();
    console.log(this.documentTemplateEditar)
    if (this.documentTemplateEditar !== null) {
      if (this.documentTemplateEditar.action === 'editar') {
        this.edit();
      } else {
        this.duplicar();
      }
    }
    this.documentTemplateForm = this.fb.group({
      id: [0],
      moduloId: [this.usuarioData.modulo.id],
      name: ['', Validators.required],
      descriprion: ['', Validators.required],
      template: ['', Validators.required],
      templateCustom: [''],
      header: [''],
      footer: [''],
      personalizar: ''
    })

  }

  ngAfterViewInit() {
    // Verifique se o elemento editorElement está presente antes de inicializar o Quill Editor
    if (this.editorElement && this.editorElement.nativeElement) {
      this.initQuillEditor();
    }
  }

  toggleContent() {
    this.ngZone.run(() => {
      // this.checkboxChecked = !this.checkboxChecked;
      this.changeDetectorRef.detectChanges(); // Força a detecção de mudanças
    });
  }

  stringToArrayBuffer(str: string): ArrayBuffer {
    const buffer = new ArrayBuffer(str.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < str.length; i++) {
      bufferView[i] = str.charCodeAt(i);
    }
    return buffer;
  }

  toggleDiv() {
    this.exibirDiv = !this.exibirDiv;
  }  

  closeModal() {
    this.carregandoCadastro = false;
    this.dialogRef.close();
  }


  onSubmit() {
    if (this.documentTemplateForm.valid) {
      this.create(this.documentTemplateForm.value);
    }
  }

  create(data) {
    this.carregandoCadastro = true;
    this.autenticacao.abrirDialogo().subscribe(response => {
      if (response) {

        data.status = 'true';

        const encoder = new TextEncoder();
        const templateBytes = encoder.encode(this.htmlContent); // Convertendo o conteúdo HTML em bytes
        const templateBase64 = btoa(String.fromCharCode.apply(null, templateBytes)); // Convertendo bytes para base64
        data.template = templateBase64; // Definindo o campo template no DTO como base64

        if (this.checkboxChecked === false) {
          data.header = null;
          data.footer = null;
        } else {
          const headerBytes = encoder.encode(this.htmlContentHeader);
          const footerBytes = encoder.encode(this.htmlContentFooter);
          const headerBase64 = btoa(String.fromCharCode.apply(null, headerBytes));
          const footerBase64 = btoa(String.fromCharCode.apply(null, footerBytes));
          data.header = headerBase64;
          data.footer = footerBase64;
        }

        this.documentTemplateService.save(data).subscribe(result => {
          this.documentTemplateForm.reset();
          this.carregandoCadastro = false;
          this.snackBarNotification('Template de Documento cadastrado com sucesso!', 'sucesso');
          this.dialogRef.close(true);
        },
          err => {
            this.carregandoCadastro = false;
            this.snackBarNotification(err.error.message, 'erro');
          })

      } else {
        this.carregandoCadastro = false;
      }
    });
  }


  edit() {
    this.carregandoCadastro = true;
    this.textoSubmit = 'Alterar';
    this.documentTemplateService.getOne(this.documentTemplateEditar.id).subscribe(result => {
      this.documentTemplate = result;

      const templateBytes = atob(String(result.template)); // Decodifica o conteúdo Base64 para bytes
      const templateArrayBuffer = this.stringToArrayBuffer(templateBytes); // Converte os bytes para ArrayBuffer
      const templateString = new TextDecoder().decode(templateArrayBuffer); // Decodifica o ArrayBuffer para string
      this.htmlContent = templateString; // Atribui a string ao this.htmlContent

      let headerString;
      let footerString;
      //let templateCustomString;
      if (result.header == null) {
        headerString = this.htmlContentHeader;
        this.htmlContentHeader = headerString;
      } else {
        this.checkboxChecked = true;
        const headerBytes = atob(String(result.header));
        const headerArrayBuffer = this.stringToArrayBuffer(headerBytes);
        headerString = new TextDecoder().decode(headerArrayBuffer);
        this.htmlContentHeader = headerString;
      }

      if (result.footer == null) {
        footerString = this.htmlContentFooter;
        this.htmlContentFooter = footerString;
      } else {
        this.checkboxChecked = true;
        const footerBytes = atob(String(result.footer));
        const footerArrayBuffer = this.stringToArrayBuffer(footerBytes);
        footerString = new TextDecoder().decode(footerArrayBuffer);
        this.htmlContentFooter = footerString;
      }

      //if (this.checkboxChecked == true) {
        //templateCustomString = this.htmlContent;
        //this.htmlContentTemplate = templateString;
      //}

      this.documentTemplateForm.patchValue({
        id: result.id,
        moduloId: result.moduloId,
        name: result.name,
        descriprion: result.descriprion,
        template: templateString,
        //templateCustom: templateString,
        header: headerString,
        footer: footerString,
        personalizar: this.checkboxChecked
      })
      this.updatePreview();
      this.carregandoCadastro = false;
    })
  }

  duplicar() {
    this.carregandoCadastro = true;
    this.textoSubmit = 'Duplicar';
    this.documentTemplateService.getOne(this.documentTemplateEditar.id).subscribe(result => {
      this.documentTemplate = result;
      const templateBytes = atob(String(result.template)); // Decodifica o conteúdo Base64 para bytes
      const templateArrayBuffer = this.stringToArrayBuffer(templateBytes); // Converte os bytes para ArrayBuffer
      const templateString = new TextDecoder().decode(templateArrayBuffer); // Decodifica o ArrayBuffer para string
      this.htmlContent = templateString; // Atribui a string ao this.htmlContent

      let headerString;
      let footerString;
      if (result.header == null) {
        headerString = this.htmlContentHeader;
        this.htmlContentHeader = headerString;
      } else {
        this.checkboxChecked = true;
        const headerBytes = atob(String(result.header));
        const headerArrayBuffer = this.stringToArrayBuffer(headerBytes);
        headerString = new TextDecoder().decode(headerArrayBuffer);
        this.htmlContentHeader = headerString;
      }

      if (result.footer == null) {
        footerString = this.htmlContentFooter;
        this.htmlContentFooter = footerString;
      } else {
        this.checkboxChecked = true;
        const footerBytes = atob(String(result.footer));
        const footerArrayBuffer = this.stringToArrayBuffer(footerBytes);
        footerString = new TextDecoder().decode(footerArrayBuffer);
        this.htmlContentFooter = footerString;
      }

      this.documentTemplateForm.patchValue({
        id: 0,
        moduloId: result.moduloId,
        name: result.name,
        descriprion: result.descriprion,
        template: templateString,
        //templateCustom: templateString,
        header: headerString,
        footer: footerString,
        personalizar: this.checkboxChecked
      })
      this.updatePreview();
    })
    this.carregandoCadastro = false;
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  onChangedEditor(event: any): void {
    if (event.html) {
      this.htmlContent = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  onChangedEditorHeader(event: any): void {
    if (event.html) {
      this.htmlContentHeader = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  onChangedEditorFooter(event: any): void {
    if (event.html) {
      this.htmlContentFooter = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  updatePreview() {
    // Atualiza o conteúdo do preview usando o HTML atual do Quill
    const previewElement = document.getElementById('preview');
    if (previewElement) {
      previewElement.innerHTML = this.htmlContent;
    }

    this.carregarPdf();

  }

  carregarConfig() {
    this.carregandoCadastro = true;
    this.documentTemplateService.getConfig(this.usuarioData.modulo.id).subscribe(result => {
      this.documentConfig = result;

      const headerBytes = atob(String(result.header));
      const footerBytes = atob(String(result.footer));
      const waterMarkBytes = atob(String(result.waterMark));
      const headerArrayBuffer = this.stringToArrayBuffer(headerBytes);
      const footerArrayBuffer = this.stringToArrayBuffer(footerBytes);
      const waterMarkArrayBuffer = this.stringToArrayBuffer(waterMarkBytes);
      const headerString = new TextDecoder().decode(headerArrayBuffer);
      const footerString = new TextDecoder().decode(footerArrayBuffer);
      const waterMarkString = new TextDecoder().decode(waterMarkArrayBuffer);
      this.htmlContentHeader = headerString;
      this.htmlContentFooter = footerString;
      this.htmlContentWaterMark = waterMarkString;


      // this.updatePreview();
      this.carregandoCadastro = false;
    })
  }

  async getImageDataUrl(imageUrl: string): Promise<string> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        resolve(imageDataUrl);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async generatePDF() {
    const imageUrl = this.logoCliente;
    const imageDataUrl = await this.getImageDataUrl(imageUrl);
    const image = new Image();
    image.src = imageDataUrl;
    await image.decode(); // Esperar a imagem ser carregada

    const imageWidth = 300;
    const imageHeight = (image.height * imageWidth) / image.width;

    // Converte o conteúdo HTML para o formato do pdfMake
    const pdfMakeContent = htmlToPdfMake(this.htmlContent);
    const pdfMakeContentHeader = htmlToPdfMake(this.htmlContentHeader);
    // const pdfMakeContentHeader2 = pdfMakeContentHeader === 'undefined' ? '' : pdfMakeContentHeader;
    const pdfMakeContentFooter = htmlToPdfMake(this.htmlContentFooter);
    const pdfMakeContentWaterMark = htmlToPdfMake(this.htmlContentWaterMark);

    // Define a definição do documento PDF
    const docDefinition = {
      pageSize: 'A4',
      header: { text: pdfMakeContentHeader, style: 'header' },
      footer: { text: pdfMakeContentFooter, style: 'footer' },
      // watermark: { text: 'test watermark', color: 'blue', opacity: 0.3, bold: true, italics: false },
      content: [
        pdfMakeContent,
      ],
      background: [
        {
          image: imageDataUrl, // O dataURL da imagem da marca d'água
          width: imageWidth, // Largura da imagem da marca d'água
          height: imageHeight, // Altura da imagem da marca d'água
          opacity: 0.25, // Opacidade da marca d'água (0 a 1)
          absolutePosition: { x: 150, y: 200 } // Posição absoluta da marca d'água no PDF
        }
      ],
      styles: {
        header: { fontSize: 12, margin: [10, 10, 10, 10] },
        footer: { fontSize: 12, margin: [10, 10, 10, 10] },
        preview: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
        // 'ql-align-center':  { text-align: center },
        'ql-align-justify': { alignment: 'justify' },
        'ql-align-center': { alignment: 'center' },
        'ql-align-left': { alignment: 'left' },
        'ql-align-right': { alignment: 'right' },
        'ql-size-small': { fontSize: '9' },
        'ql-size-large': { fontSize: '24' },
        'ql-size-huge': { fontSize: '32' }
      },
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);

    return pdfDoc;

    // Abre o PDF em uma nova janela ou baixa o PDF
    // pdfDoc.open(); // Isso abrirá o PDF em uma nova janela
    // Ou
    // pdfDoc.download('template-preview.pdf'); // Isso irá baixar o PDF com o nome 'template-preview.pdf'

    // pdfDoc.getDataUrl((dataUrl) => {
    //  const pdfContainer = document.getElementById('pdfContainer');
    //  pdfContainer.innerHTML = `<iframe src="${dataUrl}" width="100%" height="100%"></iframe>`;
    // });

  }

  async carregarPdf() {

    const pdfDoc = await this.generatePDF();
    pdfDoc.getDataUrl((dataUrl) => {
      const pdfContainer = document.getElementById('pdfContainer');
      pdfContainer.innerHTML = `<iframe src="${dataUrl}" width="100%" height="100%"></iframe>`;
    });

  }

  async abrirPdf() {

    const pdfDoc = await this.generatePDF();

    pdfDoc.open()

  }

  /**/
  private initQuillEditor(): void {
    this.quill = new Quill(this.editorElement.nativeElement, {
      modules: {
        toolbar: this.modulesQuill.toolbar,
      },
      theme: 'snow',
    });

    // Assine o evento 'text-change' do Quill Editor
    this.quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
      if (source === 'user') {
        const content = this.quill.root.innerHTML;
        this.onChangedEditor({ html: content });
      }
    });
  }

}
