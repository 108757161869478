import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CredenciaisService {

  constructor() { }

  idUsuario() {
    return JSON.parse(localStorage.getItem('usuarioData')).idUsuario;
  }

  idModulo() {
    return JSON.parse(localStorage.getItem('usuarioData')).modulo.id;
  }
}
