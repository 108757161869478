import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { PainelComponent } from './modules/painel/painel.component';
import { ListaProcessosComponent } from './modules/lista-processos/lista-processos.component'
import { CadastrarProcessoComponent } from './modules/cadastrar-processo/cadastrar-processo.component'
import { ConsultarProcessoComponent } from './modules/consultar-processo/consultar-processo.component';
import { VincularAitComponent } from './modules/vincular-ait/vincular-ait.component';
import { DocumentTemplateComponent } from './modules/document-template/document-template.component';

const routes: Routes = [
  { path: '', redirectTo: '/painel', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { nomeSecao: 'Login' } },
  { path: 'painel', component: PainelComponent, data: { nomeSecao: 'Dashboard' } },
  { path: 'lista-processos', component: ListaProcessosComponent, data: { nomeSecao: 'Processos no Módulo' } },
  { path: 'consultar-processo', component: ConsultarProcessoComponent, data: { nomeSecao: 'Consultar Processo' } },
  { path: 'cadastrar-processo', component: CadastrarProcessoComponent, data: { nomeSecao: 'Criar Processo' } },
  { path: 'vincular-ait', component: VincularAitComponent, data: { nomeSecao: 'Vincular Ait' } },
  { path: 'document-template', component: DocumentTemplateComponent, data: { nomeSecao: 'Modelo de Documentos' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
