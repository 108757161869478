import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarcadorComponent } from './marcador.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { SolicitarAutenticacaoModule } from '../solicitar-autenticacao/solicitar-autenticacao.module';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoginService } from 'src/app/modules/login/service/login-provider';
import { MarcadorService } from './services/marcador.service';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [MarcadorComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    MatSnackBarModule,
    MatSelectModule,
    SolicitarAutenticacaoModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  providers: [
    LoginService,
    MarcadorService,
  ]
})
export class MarcadorModule { }
