import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { ValidarArquivoComponent } from 'src/app/shared/components/validar-arquivo/validar-arquivo.component';
import { Observable } from 'rxjs';
import { Arquivo } from '../models/arquivo.model';

@Injectable({
  providedIn: 'root'
})
export class ValidarArquivoService {

  constructor(public dialog: MatDialog) { }
  
  // Essa função cria um observable que retorna 'true' quando o arquivo for validado
  abrirValidarArquivo(arquivoSelecionado: Arquivo){
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(ValidarArquivoComponent, {
      data: {
        arquivoSelecionado,
        validar: true
      }
    })
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }

  // Essa função cria um observable que retorna 'true' quando o arquivo for invalidado
  abrirInvalidarArquivo(arquivoSelecionado: Arquivo){
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(ValidarArquivoComponent, {
      data: {
        arquivoSelecionado,
        validar: false
      }
    })
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }
}

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { ValidarArquivoModule } from 'src/app/shared/components/validar-arquivo/validar-arquivo.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { ValidarArquivoService } from 'src/app/shared/services/validar-arquivo.service';

      constructor(public validarArquivoService: ValidarArquivoService) { }
*/