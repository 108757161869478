import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AtualizarSenha } from 'src/app/shared/models/atualizar-senha.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('engine:engine')
  })
};

@Injectable({
  providedIn: 'root'
})
export class AtualizarSenhaService {

  constructor(
    private http: HttpClient,
    ) { }

  atualizarSenha(body: AtualizarSenha) {
    return this.http.put( `${environment.apiAdmin}usuario/atualizar-senha/${JSON.parse(localStorage.getItem('usuarioData')).idUsuario}?idUsuarioLogado=${JSON.parse(localStorage.getItem('usuarioData')).idUsuario}`, body, httpOptions);
  }
}
