import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable()
export class MarcadorService {

  constructor(
    private http: HttpClient,
    ) { }

  addMarcador(bodyMarcador){
    return this.http.post(`${environment.caminhoApi}marcador-processo`, bodyMarcador, httpOptions);
  }

}
