import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa('engine:engine')}`
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdministrativoService {

  idModulo: number;
  idUsuario: number;
  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idModulo = this.usuarioData.modulo.id;
    this.idUsuario = this.usuarioData.idUsuario;
  }

  listarUsuarios() {
    return this.http.get(`${environment.apiAdmin}usuario?idModulo=${this.idModulo}`, httpOptions);
  }

  cadastrarUsuario(body) {
    return this.http.post(`${environment.apiAdmin}usuario?idUsuarioLogado=${this.idUsuario}`, body, httpOptions);
  }

  listarNiveisAcesso() {
    return this.http.get(`${environment.apiAdmin}niveis-acesso`, httpOptions);
  }

  listarModulos() {
    return this.http.get(`${environment.apiAdmin}modulos`, httpOptions);
  }

}
