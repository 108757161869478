import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { UsuarioData } from '../../models/usuarioData.model';
import { FileUploadService } from '../../services/file-upload.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
@Component({
  selector: "app-cadastrar-arquivo",
  templateUrl: "./cadastrar-arquivo.component.html",
  styleUrls: ["./cadastrar-arquivo.component.css"]
})
export class CadastrarArquivoComponent {
  usuarioData: UsuarioData;
  arquivos = [];
  carregando: boolean = false;
  arquivosNovosAdicionados: boolean = false;

  public assinadoAll: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CadastrarArquivoComponent>,
    public _snackBar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private autenticacao: SolicitarAutenticacaoService,
    @Inject(MAT_DIALOG_DATA) public idProcesso,
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  }

  retiraArquivo(index) {
    this.arquivos.splice(index, 1);
  }

  adicionaArquivo(event) {
    const fileTypeRegex = /^(application\/pdf)$/;
    for (let index = 0; index < event.length; index++) {

      if (event[index].type.match(fileTypeRegex)) {

        this.getBase64(event[index])
          .subscribe(valorBase64 => {
            const arquivo = {
              descricao: '',
              documento: valorBase64,
              idModulo: this.usuarioData.modulo.id,
              idUsuario: this.usuarioData.idUsuario,
              nomeArquivo: event[index].name,
            }
            this.arquivos.push(arquivo)
          })

      } else {

        alert(`${event[index].name} tem formato de arquivo invalido! Esta Aplicação so aceitas arquivos no formato .pdf`);

      }
    }
  }

  getBase64(file) {
    return new Observable(observavel => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        observavel.next(reader.result)
        observavel.complete();
      }
    })
  }

  upload() {
    if (this.arquivos.length !== 0) {
      this.carregando = true;

      this.arquivos.map((arquivo)=>{
          arquivo["assinado"] = this.assinadoAll;
      })

      //alert(this.arquivos)
      //alert(this.assinadoAll)

      this.autenticacao.abrirDialogo()
        .subscribe(resposta => {
          if (resposta) {

            this.fileUploadService.uploadArquivo(this.arquivos, this.idProcesso).subscribe(
              res => {
                console.log(res)
                //alert(res)
                this.carregando = false;
                this.arquivosNovosAdicionados = true;
                this.arquivos = [];
                this._snackBar.open('Arquivos Cadastrados com Sucesso!', '', {
                  duration: 4000,
                  verticalPosition: 'top',
                  horizontalPosition: 'end',
                  panelClass: ['sucesso']
                });
              },
              err => {
                this.carregando = false;
                console.error(err);
                this._snackBar.open(err.error.message, '', {
                  duration: 4000,
                  verticalPosition: 'top',
                  horizontalPosition: 'end',
                  panelClass: ['erro']
                });
              }
            );

          } else {
            this.carregando = false;
          }
        })
    }
  }

  fecharModal(){
    this.dialogRef.close(this.arquivosNovosAdicionados)
  }

}
