import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { VincularAitService } from './services/vincular-ait.service';
import { SelecionarAitComponent } from './selecionar-ait/selecionar-ait.component';
import { SelecionarProcessoComponent } from './selecionar-processso/selecionar-processo.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-vincular-ait',
  templateUrl: './vincular-ait.component.html',
  styleUrls: ['./vincular-ait.component.css']
})
export class VincularAitComponent implements OnInit {

  cadastrarProcessoForm: FormGroup;
  usuarioData;
  carregandoVinculacao = false;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  processoSelecionado = {
    id: null,
    numeroProtocolo: null,
    numeroAit: null,
    nomeInteressado: null,
    moduloAtendimento: null,
    enumPrioridadeAtendimento: null,
    tipoPessoaEnum: null,
    tipoProcessoEnum: null,
    tipoProcessoEnumHex: null,
    situacaoEnum: null,
    situacaoEnumHex: null,
    dthCadastro: null,
    alocado: null,
    origem: null,
    nomeCondutor: null,
    documento: null,
    placa: null,
    tipificacao: null,
    dthOcorrencia: null
  }
  aitSelecionada = {
    enumOrigemAit: null,
    numero: null,
    placa: null,
    tipificacao: null,
    nomeCondutor: null,
    enumTipoDocumentoCondutor: null,
    documentoCondutor: null,
    dthOcorrencia: null,
    tipoDocumentoCondutor: null,
  }

  constructor(
    private _formBuilder: FormBuilder,
    public listarAitsTalonario: VincularAitService,
    private dialog: MatDialog,
    private notificacao: MatSnackBar,
    public autenticar: SolicitarAutenticacaoService,
    private router: Router,
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
   }

  ngOnInit() {
    this.cadastrarProcessoForm = this._formBuilder.group(
      {
        documentoCondutor: [''],
        dthOcorrencia: ['',Validators.required],
        enumTipoDocumentoCondutor: [''],

        idModulo: [this.usuarioData.modulo.id],
        idProcesso: ['',Validators.required],
        idUsuario: [this.usuarioData.idUsuario],

        nomeCondutor: [''],
        numero: ['',Validators.required],
        placa: ['',Validators.required],
        tipificacao: ['',Validators.required],
        justificativa: ['']
      });
  }

  onSubmit() {
    if (this.cadastrarProcessoForm.valid) {
      this.vincularAIT(this.cadastrarProcessoForm.value);
    }
  }

  escolherAit() {
    const referenciaDiaologo = this.dialog.open(SelecionarAitComponent, {
      maxHeight: '80vw',
      width: '100vw'
    });

    referenciaDiaologo.afterClosed()
      .subscribe(value => {
        if (value !== undefined) {
          this.aitSelecionada = value;
          console.log(value);
        } else {
          console.log('Nenhum processo selecionado!')
        }
      });
  }

  escolherProcesso() {
    const referenciaDiaologo = this.dialog.open(SelecionarProcessoComponent, {
      maxHeight: '80vw',
      width: '100vw'
    });

    referenciaDiaologo.afterClosed()
      .subscribe(value => {
        if (value !== undefined) {
          this.processoSelecionado = value;
          console.log(value);
        } else {
          console.log('Nenhum processo selecionado!')
        }
      });
  }

  vincularAIT(processo){
    console.log(this.cadastrarProcessoForm.value);
    this.carregandoVinculacao = true;
    this.autenticar.abrirDialogo()
    .subscribe(resposta=>{
      if(resposta){

        this.listarAitsTalonario.vincularAIT(processo)
        .subscribe(res=>{
          this.carregandoVinculacao = false;
          this.snackBarNotification('AIT vinculada a processo realizada com sucesso!','sucesso');
          this.router.navigate(['/lista-processos']);
          console.log(res);
        },
        error=>{
          this.carregandoVinculacao = false;
          this.snackBarNotification(error.error.message, 'erro');
          console.log(error.error.message);
        })

      } else {
        this.carregandoVinculacao = false;
      }
    })

  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
