import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioData } from '../../models/usuarioData.model';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {
  usuarioData: UsuarioData

  constructor() {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  } 
  
  arquivos = [];

  @Output() listaArquivos = new EventEmitter();

  @Input('descricao') descricaoArquivos: string;

  retiraArquivo(index) {
    this.arquivos.splice(index, 1);
    if(this.arquivos.length !== 0){
      this.listaArquivos.emit(this.arquivos);
    }else{
      this.listaArquivos.emit(null)
    }
  }

  adicionaArquivo(event) {
    const fileTypeRegex = /^(application\/pdf)$/;
    for (let index = 0; index < event.length; index++) {

      if (event[index].type.match(fileTypeRegex)) {

        this.getBase64(event[index])
        .subscribe(valorBase64=>{
          const arquivo = {
            descricao: this.descricaoArquivos,
            documento: valorBase64,
            idModulo: this.usuarioData.modulo.id,
            idUsuario: this.usuarioData.idUsuario,
            nomeArquivo: event[index].name,
          }
          this.arquivos.push(arquivo)
        })

      } else {

        alert(`${event[index].name} tem formato de arquivo invalido! Esta Aplicação so aceitas arquivos no formato .pdf`);

      }
    }
    this.listaArquivos.emit(this.arquivos);
  }

  getBase64(file){
    return new Observable(observavel=>{
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        observavel.next(reader.result)
        observavel.complete();
      }
    })
  }
  
}