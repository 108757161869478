import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { MarcadorComponent } from 'src/app/shared/components/marcador/marcador.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarcadorProcessoService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(processosSelecionados){
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(MarcadorComponent, {data: processosSelecionados})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }
}

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { MarcadorModule } from 'src/app/shared/components/marcador/marcador.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { MarcadorService } from 'src/app/shared/services/marcar-processo.service';

      constructor(public MarcadorProcessoService: MarcadorProcessoService) { }
*/