import { Observable } from 'rxjs';
import { SolicitarAutenticacaoService } from './../../../services/solicitar-autenticacao.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/modules/login/service/login-provider'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable()
export class JuntarService {
  constructor(private http: HttpClient) { }

  juntarProcessos(bodyJuntada){

    bodyJuntada["idUsuario"] = JSON.parse(localStorage.getItem('usuarioData')).idUsuario;
    bodyJuntada["idModulo"] = JSON.parse(localStorage.getItem('usuarioData')).modulo.id;

    console.log(bodyJuntada);

    return this.http.post(`${environment.caminhoApi}juntar-processos`, bodyJuntada, httpOptions);
  }

}
