import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { ManualComponent } from 'src/app/shared/components/manual/manual.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManualService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(){
    // Abre o Dialog para detalhar processo
    const referenciaDiaologo = this.dialog.open(ManualComponent, {
      panelClass: 'manual',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
    })
  }
} 

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { ManualModule } from 'src/app/shared/components/manual/manual.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { ManualService } from 'src/app/shared/services/manual.service';

      constructor(public detalhaProcesso: ManualService) { }
*/