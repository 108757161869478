import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessoDetalhadoService } from '../services/processo-detalhado.service';
import { Observable, Observer } from "rxjs";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-visualizar-arquivo',
  templateUrl: './visualizar-arquivo.component.html',
  styleUrls: ['./visualizar-arquivo.component.css']
})
export class VisualizarArquivoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public arquivo,
    public processoService: ProcessoDetalhadoService,
    public dialogRef: MatDialogRef<VisualizarArquivoComponent>,
    public _snackBar: MatSnackBar,
  ) { }

  @ViewChild('pdfViewer', { static: true }) public visualizador;
  carregamentoDocumentos = true;

  ngOnInit() {
    this.visualizador.openFile = false;
    this.previewPdf(this.arquivo.id, this.arquivo.nome);
  }

  previewPdf(id, nomeArquivo){
    this.processoService.arquivoBase64(id).subscribe((res:any)=>{
      this.pdfBase64toBlob(res[0].base64).subscribe(file=>{
        this.carregamentoDocumentos = false;
        this.visualizador.downloadFileName = nomeArquivo;
        this.visualizador.pdfSrc = file;
        this.visualizador.refresh();
       })
    },
    error=>{
      console.log(error);
      this.carregamentoDocumentos = false;
      this._snackBar.open(`${error.status} - ${error.error.message}`, '', {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'end',
        panelClass: ['erro']
      });
      this.dialogRef.close();
    })
  }

  pdfBase64toBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<any>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      observer.next(int8Array);
      observer.complete();
    });
  }

}
