import { SolicitarAutenticacaoService } from './../../services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DistribuirSugestoes, distribuirDePara, distribuirUsuariosDestino } from './../../models/distribuir';
import { FormControl, Validators } from '@angular/forms';
import { ProcessoSelecionado } from "./../../models/processo-selecionado.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { ProcessoDetalhadoService } from "src/app/shared/services/processo-detalhado.service";
import { DistribuirService } from "./services/distribuir.service";
import { element } from 'protractor';

@Component({
  selector: "app-distribuir-processo",
  templateUrl: "./distribuir-processo.component.html",
  styleUrls: ["./distribuir-processo.component.css"],
})
export class DistribuirProcessoComponent implements OnInit {

  public idsProcessos: Number[] = [];
  public idsUsuariosDestino: distribuirUsuariosDestino[] = [];

  public displayedColumns: string[] = ["protocolo", "name", "distribuir"];
  public usuarioData: any;
  public justificativaLabel: string;
  public carregandoDistribuir: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public selectedProcessos: Array<ProcessoSelecionado>,
    public dialogRef: MatDialogRef<DistribuirProcessoComponent>,
    private detalharProcesso: ProcessoDetalhadoService,
    private $distribuirProcessos: DistribuirService,
    public $autenticacaoService: SolicitarAutenticacaoService,
    public _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    //Getting idsProcessos
    this.selectedProcessos.forEach((processo) => { this.idsProcessos.push(processo.id) });

    // Resquest '/distribuir/params'; params: { 'idsProcesso' }
    this.distribuirParamsUsuarios(this.idsProcessos);
  }

  private addUsuarioSugestao(_dePara: distribuirDePara[], _selectedProcessos: Array<ProcessoSelecionado>): Array<ProcessoSelecionado> {
    // Add UsuariosSugestão in SelectedProcessos
    _selectedProcessos.forEach((processo, index, array) => {
      processo['idUsuario'] = _dePara[index].usuarioSugestao.id;
    });

    return _selectedProcessos;
  }

  // Resquest '/distribuir/params'; params: { 'idsProcesso: Number[]' }
  private distribuirParamsUsuarios(_idsProcessos: Number[]) {
    this.$distribuirProcessos
      .distribuirParams({ "idsProcesso": this.idsProcessos })
      .subscribe((res) => {
        const { dePara, idsUsuariosDestino } = res;

        this.idsUsuariosDestino = idsUsuariosDestino;
        this.selectedProcessos = this.addUsuarioSugestao(dePara, this.selectedProcessos);

        this.carregandoDistribuir = false;
      }, err => {
        this.snackBarNotification(err.error.message, "erro");
        this.dialogRef.close(true);

      });
  }



  // Resquest '/distribuir'; params: { 'dePara : distribuirDePara[]', 'justificativa: String' }
  public distribuirUsuarios() {

    try {
      if (!this.justificativaLabel) throw new Error('O campo descrição não pode ser vazio');

      this.carregandoDistribuir = true;


      let _selectedProcessos: ProcessoSelecionado[] = this.selectedProcessos;
      let _justificativa: String = this.justificativaLabel || '';
      let _dePara: distribuirDePara[] = [];

      _selectedProcessos.forEach((procesos, index, array) => {
        _dePara.push({
          "idProcesso": procesos.id,
          "idUsuario": procesos['idUsuario']
        })
      })

      this.$autenticacaoService.abrirDialogo().subscribe(
        res => {
          this.snackBarNotification("Distribuindo Processos...", "warn");

          if (res) {
            this.$distribuirProcessos.distribuir({ "dePara": _dePara, "justificativa": _justificativa }).subscribe(
              res => {
                this.snackBarNotification("Processos distribuidos com Sucesso !", "sucesso");
                this.dialogRef.close(true);
                this.carregandoDistribuir = false;
              },
              err => {
                this.carregandoDistribuir = false;
                this.snackBarNotification(err.error.message, "erro");
              });
          } else {
            this.carregandoDistribuir = false;
            this.snackBarNotification("Error na autenticação", "erro");
          }
        });

    } catch (error) {
        this.snackBarNotification(error.message, "erro");
    }

  }

  detalharProcessoSelecionado(idProcesso) {
    this.detalharProcesso.abrirDialogo(idProcesso, false).subscribe();
  }

  snackBarNotification(mensagem, tipo) {
    this._snackBar.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
