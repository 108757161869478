import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AtualizarSenhaService } from './service/atualizar-senha.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-atualizar-senha',
  templateUrl: './atualizar-senha.component.html',
  styleUrls: ['./atualizar-senha.component.css']
})
export class AtualizarSenhaComponent implements OnInit {

  carregandoAtualizacaoSenha: boolean = false;

  senhaAtualVisivel: boolean = false;
  senhaNovaVisivel: boolean = false;
  senhaNovaVisivelConfirmacao: boolean = false;

  matcher = new MyErrorStateMatcher();

  atualizarSenhaForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AtualizarSenhaComponent>,
    private notificacao: MatSnackBar,
    private atualizarSenhaService: AtualizarSenhaService
  ) { }

  ngOnInit() {
    this.atualizarSenhaForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validator: this.checkPasswords })
  }

  cadastrarNovaSenha() {

    if (this.atualizarSenhaForm.valid) {
      this.carregandoAtualizacaoSenha = true;
      this.atualizarSenhaService.atualizarSenha({
        senhaAntiga: this.atualizarSenhaForm.value.oldPassword,
        senhaNova: this.atualizarSenhaForm.value.password
      })
      .subscribe(res => {
        console.log(res);
        this.carregandoAtualizacaoSenha = false;
        this.snackBarNotification('Senha atualizada com sucesso!','sucesso');
        this.dialogRef.close()
      }, err => {
        console.log(err);
        this.carregandoAtualizacaoSenha = false;
        this.snackBarNotification(err.error.message, 'erro');
      })
    }

  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
