import { Component, ViewChild } from "@angular/core";
import { ConsultarProcessoService } from "./services/consultar-processo.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ProcessoDetalhadoService } from 'src/app/shared/services/processo-detalhado.service';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BodyFiltro } from 'src/app/shared/models/filtro.model';

import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: "app-consultar-processo",
  templateUrl: "./consultar-processo.component.html",
  styleUrls: ["./consultar-processo.component.css", "../../shared/styles/material-table-responsive.css"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', backgroundColor: 'white', borderBottomColor: 'white'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConsultarProcessoComponent {
  @ViewChild(MatPaginator, { static: false }) paginacao: MatPaginator;

  expandedElement: any | null;

  detalharTableMock = [
    {
      "id": 4347,
      "numeroProtocolo": "20210405/0004347-SGI",
      "numeroAit": "A74550",
      "nomeInteressado": "Valdo de Sousa Quadros",
      "moduloAtendimento": "Renainf",
      "enumPrioridadeAtendimento": "NORMAL",
      "tipoPessoaEnum": "NATURAL",
      "tipoProcessoEnum": "Defesa",
      "tipoProcessoEnumHex": "#00b0f0",
      "situacaoEnum": "Em andamento",
      "situacaoEnumHex": "#ffc000",
      "dthCadastro": "2021-04-05T05:01:23.000+0000",
      "alocado": "Renainf",
      "origem": "",
      "numeroProtocoloOrigem": "DP2021040005440",
      "nomeCondutor": "",
      "documento": "42536740200",
      "placa": "",
      "tipificacao": "",
      "dthOcorrencia": "",
      "marcador": "Aguardando AIT A100018707"
    },
    {
      "id": 4346,
      "numeroProtocolo": "20210405/0004346-SGI",
      "numeroAit": "TL557646",
      "nomeInteressado": "IAN ANDRÉ",
      "moduloAtendimento": "Renainf",
      "enumPrioridadeAtendimento": "NORMAL",
      "tipoPessoaEnum": "NATURAL",
      "tipoProcessoEnum": "Defesa",
      "tipoProcessoEnumHex": "#00b0f0",
      "situacaoEnum": "Em andamento",
      "situacaoEnumHex": "#ffc000",
      "dthCadastro": "2021-04-05T05:01:20.000+0000",
      "alocado": "Renainf",
      "origem": "",
      "numeroProtocoloOrigem": "DP2021030005350",
      "nomeCondutor": "",
      "documento": "01697063977",
      "placa": "",
      "tipificacao": "",
      "dthOcorrencia": "",
      "marcador": "Aguardando AR TL00557646"
    }
  ];


  dataInicio = new FormControl(new Date());
  dataFim = new FormControl(new Date());
  filtroVisivel: boolean = false;
  pipe = new DatePipe('en-US');
  // pageSize = 20;
  // pageIndex = 1;
  totalProcessosConsulta = 0;
  bodyFiltro: BodyFiltro = {
    from: null,
    idModuloAtual: JSON.parse(localStorage.getItem("usuarioData")).modulo.id,
    idUsuarioAlocado: null,
    idUsuarioLogado: JSON.parse(localStorage.getItem("usuarioData")).idUsuario,
    nomeRequerente: null,
    numAit: null,
    numPlaca: null,
    numProtocolo: null,
    pag: 1,
    qtd: 20,
    origem: null,
    status: null,
    tipo: null,
    to: null,
  };
  todasDatas: boolean = false;
  statusSelecionado = null;
  tipoSelecionado = null;
  setorSelecionado = null;
  usuarioSelecionado = null;

  /** Variáveis responsáveis por ligar o carregamento da pagina*/
  carregamentoTabela: boolean = false;
  colunas: string[] = [
    "protocolo",
    // "ait",
    // "placa",
    // "dataOcorrencia",
    // "condutor",
    "interessado",
    // "documento",
    "dataCadastro",
    "tipificacao",
    "situacao",
    "alocado",
    "detalhar",
  ];
  listagemProcessos = new MatTableDataSource();
  mostrarAcoes: boolean = false;
  mostrarUsuarios: boolean = false;
  filtro = "";
  listStatus = [
    { value: null, nome: "Todos os status" },
    { value: "0", nome: "Aberto" },
    { value: "1", nome: "Andamento" },
    { value: "2", nome: "Arquivado" },
    { value: "3", nome: "Deferido" },
    { value: "4", nome: "Indeferido" },
    { value: "5", nome: "Cancelado" },
    { value: "6", nome: "Suspenso" },
    { value: "7", nome: "Descartado" },
  ]
  listTipos = [
    { value: null, nome: "Todos os tipos" },
    { value: "0", nome: "Defesa de Autuação" },
    { value: "1", nome: "Recurso" },
    // {value: "2", nome: "Comunicação de Venda"},
    // {value: "3", nome: "Transferência  de Pontuação"},
  ]
  listSetores = [
    { value: null, nome: "Todos os Setores" },
    { value: "1", nome: "Protocolo" },
    { value: "2", nome: "Renainf" },
    { value: "3", nome: "Projur" },
    { value: "4", nome: "Jari" },
    { value: "9999", nome: "Portal Cidadão" },
  ]
  listModulos = [
    { value: "1", nome: "Protocolo" },
    { value: "2", nome: "Renainf" },
    { value: "3", nome: "Projur" },
    { value: "4", nome: "Jari" },
  ]
  listUsuarios = [];
  constructor(
    private processosService: ConsultarProcessoService,
    private notificacao: MatSnackBar,
    private detalharProcesso: ProcessoDetalhadoService,
  ) { }

  ngOnInit() {
    this.dataInicio.value.setDate(this.dataInicio.value.getDate() - 30);
    this.listarProcessos();
  }

  listarProcessos() {
    this.carregamentoTabela = true;

    if (this.bodyFiltro.nomeRequerente === '') {
      this.bodyFiltro.nomeRequerente = null;
    }
    if (this.bodyFiltro.numAit === '') {
      this.bodyFiltro.numAit = null;
    }
    if (this.bodyFiltro.numPlaca === '') {
      this.bodyFiltro.numPlaca = null;
    }
    if (this.todasDatas) {
      this.bodyFiltro.from = null;
      this.bodyFiltro.to = null;
    } else {
      this.bodyFiltro.from = this.transformarData(this.dataInicio.value);
      this.bodyFiltro.to = this.transformarData(this.dataFim.value);
    }

    if (this.bodyFiltro.numProtocolo === '') {
      this.bodyFiltro.numProtocolo = null;
    }

    this.processosService.filtro(this.bodyFiltro).subscribe(
      (res: any) => {
        this.carregamentoTabela = false;
        this.listagemProcessos.data = res.itens;
        this.totalProcessosConsulta = res.qtd;
        console.log(res);

        //this.listagemProcessos.data[0]['itens'] = this.detalharTableMock;
      },
      err => {
        this.carregamentoTabela = false;
        this.listagemProcessos.data = [];
        this.totalProcessosConsulta = 0;
        if (err.status === 404) {
          this.snackBarNotification('Nenhum processo encontrado na pesquisa!', 'erro');
        }
      }
    );
  }

  doFilter(valor: string) {
    this.listagemProcessos.filter = valor.trim().toLocaleLowerCase();
  }

  detalhe(idProcesso: number) {
    this.detalharProcesso.abrirDialogo(idProcesso, this.mostrarAcoes)
      .subscribe(res => {
        if (res) {
          this.listarProcessos();
        }
      })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  transformarData(data: Date) {
    return this.pipe.transform(data, 'yyyy-MM-dd')
  }

  pageEvent(event) {
    this.bodyFiltro.pag = event.pageIndex + 1;
    this.bodyFiltro.qtd = event.pageSize;
    this.listarProcessos();
  }

  novaBusca() {
    this.paginacao.firstPage();
    this.bodyFiltro.pag = 1;
    this.totalProcessosConsulta = 0;
    this.listarProcessos()
  }

  listarUsuarioModulo(event) {
    console.log(event)
    if (this.bodyFiltro.origem !== null) {
      this.mostrarUsuarios = false;
      this.processosService.listarUsuariosBusca(event.value).subscribe(
        (res: Array<any>) => {
          this.mostrarUsuarios = true;
          this.listUsuarios = res.map(item => {
            return { value: item.id, nome: item.nome }
          })
          this.listUsuarios.unshift({ value: 1, nome: 'Todos os Usuarios' });
          this.listUsuarios.unshift({ value: 0, nome: 'Não Alocado' });
        }
      )
    } else {
      this.mostrarUsuarios = false;
      this.bodyFiltro.idUsuarioAlocado = null;
    }
  }

  sizeTop() {
    return window.innerWidth > 767 ? 60 : 120;
  }

  atualizarValorSelecao(event, matriz) {
    return matriz.find(item => item.value === event.value).nome;
  }

}
