import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/modules/login/service/login-provider'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable()
export class ModulosService {
  idUsuario;
  idSistema = 2;
  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idUsuario = this.usuarioData.idUsuario;
  }

  listarModulos(): Observable<[]> {
    return this.http.get<[]>(environment.apiAdmin +
      `modulos-destino?idUsuario=${JSON.parse(localStorage.getItem('usuarioData')).idUsuario}`,
      httpOptions);
  }

  listarUsuarios(): Observable<[]> {
    return this.http.get<[]>(environment.apiAdmin +
      `usuarios-modulo?idModulo=${JSON.parse(localStorage.getItem('usuarioData')).modulo.id}`,
      httpOptions);
  }

  tramitar(processos){
    return this.http.post(`${environment.caminhoApi}tramitar`, processos, httpOptions);
  }

  encaminharPessoa(processos){
    return this.http.post(`${environment.caminhoApi}encaminhar`, processos, httpOptions);
  }

}
