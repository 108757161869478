import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { ProcessoDetalhadoComponent } from 'src/app/shared/components/processo-detalhado/processo-detalhado.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessoDetalhadoService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(processo: number, mostrarAcoes: boolean){
    // Abre o Dialog para detalhar processo
    const referenciaDiaologo = this.dialog.open(ProcessoDetalhadoComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
      data: { idProcesso: processo, acoes: mostrarAcoes }
    })

    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed().
      subscribe(value => {
        observavel.next(value);
        observavel.complete();
      });
    })
  }
} 

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { ProcessoDetalhadoModule } from 'src/app/shared/components/processo-detalhado/processo-detalhado.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { ProcessoDetalhadoService } from 'src/app/shared/services/processo-detalhado.service';

      constructor(public detalhaProcesso: ProcessoDetalhadoService) { }
*/