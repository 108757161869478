import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessoDetalhadoService } from '../services/processo-detalhado.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-retificar-processo',
  templateUrl: './retificar-processo.component.html',
  styleUrls: ['./retificar-processo.component.css']
})
export class RetificarProcessoComponent implements OnInit {

  retificacao = {
    status: null,
    especie: null,
    email: null,
    descricao: null
  }

  carregandoRetificacao = true;

  listStatus = [
    {value: "ABERTO", nome: "Aberto"},
    {value: "ANDAMENTO", nome: "Andamento"},
    {value: "ARQUIVADO", nome: "Arquivado"},
    {value: "DEFERIDO", nome: "Deferido"},
    {value: "INDEFERIDO", nome: "Indeferido"},
    {value: "CANCELADO", nome: "Cancelado"},
    {value: "SUSPENSO", nome: "Suspenso"},
    {value: "DESCARTADO_PROTOCOLO", nome: "Descartado"}
  ]
  listEspecie = [
    { value: 'DEFESA_AUTUACAO', nome: 'Defesa Autuação' },
    { value: 'RECURSO', nome: 'Recurso' },
    //{ value: 'COMUNICACAO_VENDA', nome: 'Comunicação de Venda' },
    //{ value: 'TRANSFERENCIA_PONTUACAO', nome: 'Transferência de Pontuação' }
  ]

  constructor(
    public processoService: ProcessoDetalhadoService,
    public dialogRef: MatDialogRef<RetificarProcessoComponent>,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public idProcesso,
  ) { }

  ngOnInit(): void {
    this.processoService.dadosRetificacao(this.idProcesso).subscribe((resposta: any)=>{
      this.carregandoRetificacao = false;
      this.retificacao.email = resposta.email;
      this.retificacao.especie = resposta.tipoProcessoEnum;
      this.retificacao.status = resposta.statusEnum;
      console.log(resposta);
    },
    erro=>{
      this.carregandoRetificacao = false;
      this.dialogRef.close(false);
      this.snackBarNotification(`Não foi possivel carregar a retificação! Erro ${erro.status} - ${erro.error.error}`,'erro');
    })
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  retificarProcesso(){
    this.carregandoRetificacao = true;
    // console.table(this.listaArquivos);
    // console.table(this.formatoRequisicao(this.listaArquivos));
    this.autenticacao.abrirDialogo()
      .subscribe(resposta => {
        if (resposta) {
          this.processoService.retificarDadosProcesso({
            email: this.retificacao.email,
            idModulo: JSON.parse(localStorage.getItem("usuarioData")).modulo.id,
            idProcesso:this.idProcesso,
            idUsuario: JSON.parse(localStorage.getItem("usuarioData")).idUsuario,
            justificativa: this.retificacao.descricao,
            statusEnum: this.retificacao.status,
            tipoProcessoEnum: this.retificacao.especie
          })
            .subscribe(res=>{
              this.snackBarNotification('Retificação salva com sucesso!','sucesso');
              this.carregandoRetificacao = false;
              this.dialogRef.close(true);
            },
            error=>{
              this.snackBarNotification('Não foi possivel retificar os dados do processo','erro');
              this.carregandoRetificacao = false;
            }
          )
        } else {
          this.carregandoRetificacao = false;
        }
      }
    )
  }

}
