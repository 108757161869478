import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { AtualizarSenhaComponent } from 'src/app/shared/components/atualizar-senha/atualizar-senha.component';

@Injectable({
  providedIn: 'root'
})
export class AtualizarSenhaService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(){
    // Abre o Dialog para atualizar senha
    const referenciaDiaologo = this.dialog.open(AtualizarSenhaComponent)
  }
}

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { AtualizarSenhaModule } from 'src/app/shared/components/atualizar-senha/atualizar-senha.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { AtualizarSenhaService } from 'src/app/shared/services/atualizar-senha.service';

      constructor(public atualizarSenha: AtualizarSenhaService) { }
*/