import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessoDetalhadoService } from '../services/processo-detalhado.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CredenciaisService } from 'src/app/shared/services/credenciais.service';

@Component({
  selector: 'app-voto',
  templateUrl: './voto.component.html',
  styleUrls: ['./voto.component.css']
})
export class VotoComponent {

  constructor(
    public processoService: ProcessoDetalhadoService,
    public dialogRef: MatDialogRef<VotoComponent>,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
    private credencial: CredenciaisService,
    @Inject(MAT_DIALOG_DATA) public idProcesso: number,
  ) { }

  carregandoVoto: boolean = false;
  voto = {
    //descricao: null,
    idModulo: this.credencial.idModulo(),
    idProcesso: this.idProcesso,
    idUsuario: this.credencial.idUsuario(),
    votoEnum: null,
    //arquivos: [],
  }

  votarProcesso() {
    this.carregandoVoto = true;
    this.autenticacao.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){
        this.processoService.criarVoto(this.voto).subscribe(
          res=>{
            this.carregandoVoto = false;
            this.dialogRef.close(true);
            this.snackBarNotification('Os marcadores foram cadastrado com sucesso!','sucesso');
          },
          error=>{
            this.carregandoVoto = false;
            this.snackBarNotification(error.error.message, 'erro');
          })
      } else {
        this.carregandoVoto = false;
      }
    })
  }
  
  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  /*atualizarListaArquivosUpload(event){
    this.voto.arquivos = event;
    console.log(event);
  }*/

}
