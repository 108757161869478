import { Component, OnInit, ViewChild } from '@angular/core';
import { AdministrativoService } from './services/administrativo.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { CadastrarComponent } from './cadastrar/cadastrar.component';

@Component({
  selector: 'app-administrativo',
  templateUrl: './administrativo.component.html',
  styleUrls: ['./administrativo.component.css']
})
export class AdministrativoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  carregamentoTabela: boolean = true;
  dados = new MatTableDataSource();
  colunas: string[] = [
    // "id",
    "matricula",
    "nome",
    "login",
    "cadastro",
    // "edicao",
  ];

  constructor(
    public usuarioService: AdministrativoService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.listarTodosUsuarios();
  }


  listarTodosUsuarios() {
    this.carregamentoTabela = true;
    this.usuarioService.listarUsuarios().
      subscribe((resposta:any) => {
        this.carregamentoTabela = false;
        this.dados.data = resposta
        this.dados.paginator = this.paginator;
      }, error => {
        this.carregamentoTabela = false;
      })
  }

  busca(value: string){
    this.dados.filter = value.trim().toLocaleLowerCase();
  }

  cadastrarUsuario() {
    const dialogRef = this.dialog.open(CadastrarComponent);

    dialogRef.afterClosed().subscribe(resultado => {
      if(resultado){
        this.listarTodosUsuarios();
      }
    });
  }

}
