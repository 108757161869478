import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { RespostaCadastroProcesso } from 'src/app/shared/models/resCadastroProcesso.model';
import { RespostaProcessoPrimeiraInstancia } from 'src/app/shared/models/resProcessoPrimeiraInstancia.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};
@Injectable({
  providedIn: 'root'
})
export class CadastrarProcessoService {

  constructor(private http: HttpClient) { }

  cadastrarProcesso(processo: any): Observable<RespostaCadastroProcesso> {
    return this.http.post<RespostaCadastroProcesso>(`${environment.caminhoApi}cadastrar-processo`, processo, httpOptions);
  }

  searchProcessos(query: string): Observable<RespostaProcessoPrimeiraInstancia> {
    return this.http.get<RespostaProcessoPrimeiraInstancia>(`${environment.caminhoApi}listar-processos-primeira-instancia?search=${query}`, httpOptions);   
  }

}
