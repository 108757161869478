import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { ValidarArquivoService } from './services/validar-arquivo.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-validar-arquivo',
  templateUrl: './validar-arquivo.component.html',
  styleUrls: ['./validar-arquivo.component.css']
})
export class ValidarArquivoComponent {

  justificativa = '';
  carregandoValidadorArquivo = false;

  constructor(
    public dialogRef: MatDialogRef<ValidarArquivoComponent>,
    public autentica: SolicitarAutenticacaoService,
    public validador: ValidarArquivoService,
    private notificacao: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public arquivo,
  ) { }

  validarArquivo() {
    this.carregandoValidadorArquivo = true;
    this.autentica.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){
        this.validador.validarArquivo({
          id: this.arquivo.arquivoSelecionado.id,
          idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
          idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario,
          justificativa: this.justificativa
        }).subscribe(
          res=>{
            this.carregandoValidadorArquivo = false;
            this.dialogRef.close(true);
            this.snackBarNotification('O arquivo foi validado com sucesso!!','sucesso');
          },
          error=>{
            this.carregandoValidadorArquivo = false;
            this.snackBarNotification(error.error.message, 'erro');
          })
      } else {
        this.carregandoValidadorArquivo = false;
      }
    })
  }

  invalidarArquivo() {
    this.carregandoValidadorArquivo = true;
    this.autentica.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){
        this.validador.invalidarArquivo({
          id: this.arquivo.arquivoSelecionado.id,
          idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
          idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario,
          justificativa: this.justificativa
        }).subscribe(
          res=>{
            this.carregandoValidadorArquivo = false;
            this.dialogRef.close(true);
            this.snackBarNotification('O arquivo foi excluido com sucesso!','sucesso');
          },
          error=>{
            this.carregandoValidadorArquivo = false;
            this.snackBarNotification(error.error.message, 'erro');
          })
      } else {
        this.carregandoValidadorArquivo = false;
      }
    })
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
