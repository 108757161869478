import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable()
export class ModulosService {

  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  }

  cancelarProcesso(idsProcesso: Array<number>, descricao: string){
    return this.http.post(`${environment.caminhoApi}cancelar-processo-lote`, {
      descricao,
      idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
      idsProcesso,
      idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario
    }, httpOptions);
  }

  arquivarProcesso(idsProcesso: Array<number>, descricao: string){
    return this.http.post(`${environment.caminhoApi}arquivar-processo-lote`, {
      descricao,
      idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
      idsProcesso,
      idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario
    }, httpOptions);
  }

  deferirProcesso(idsProcesso: Array<number>, descricao: string){
    return this.http.post(`${environment.caminhoApi}deferir-processo-lote`, {
      descricao,
      idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
      idsProcesso,
      idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario
    }, httpOptions);
  }

  indeferirProcesso(idsProcesso: Array<number>, descricao: string){
    return this.http.post(`${environment.caminhoApi}indeferir-processo-lote`, {
      descricao,
      idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
      idsProcesso,
      idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario
    }, httpOptions);
  }

  descartarProcesso(idsProcesso: Array<number>, descricao: string){
    return this.http.post(`${environment.caminhoApi}descartar-processo-lote`, {
      descricao,
      idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
      idsProcesso,
      idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario
    }, httpOptions);
  }

}
