import { DistribuirProcessoModule } from './../../shared/components/distribuir-processo/distribuir-processo.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaProcessosComponent } from './lista-processos.component';
import { ListaProcessosSelecionadosComponent } from './lista-processos-selecionados/lista-processos-selecionados.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MostrarBotoesModule } from 'src/app/shared/components/mostrar-botoes/mostrar-botoes.module';
import { ProcessoDetalhadoModule } from 'src/app/shared/components/processo-detalhado/processo-detalhado.module';
import { MovimentarProcessoModule } from 'src/app/shared/components/movimentar-processo/movimentar-processo.module';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MarcadorModule } from 'src/app/shared/components/marcador/marcador.module';
import { JuntarProcessoModule } from 'src/app/shared/components/juntar-processo/juntar-processo.module';

@NgModule({
  imports: [
    CommonModule,
    MostrarBotoesModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MovimentarProcessoModule,
    MatChipsModule,
    ProcessoDetalhadoModule,
    MarcadorModule,
    MatCardModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatTooltipModule,
    NgxMaskModule,
    MatSidenavModule,
    MatToolbarModule,
    JuntarProcessoModule,
    DistribuirProcessoModule
  ],
  declarations: [
    ListaProcessosComponent,
    ListaProcessosSelecionadosComponent,
  ]
})
export class ListaProcessosModule { }
