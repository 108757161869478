import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuillModule } from 'ngx-quill';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DocumentTemplateComponent } from './document-template.component';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { DocumentTemplateFormComponent } from './document-template-form/document-template-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentModule } from './document/document.module';
import { DocumentTemplateConfigComponent } from './document-template-config/document-template-config.component';



@NgModule({
  declarations: [
    DocumentTemplateComponent,
    DocumentTemplateFormComponent,
    DocumentTemplateConfigComponent
  ],
  imports: [
    CommonModule,
    DocumentModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatChipsModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatStepperModule,
    PdfJsViewerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTooltipModule,
    QuillModule.forRoot(),
  ],
  entryComponents: [
    DocumentTemplateComponent,
    DocumentTemplateFormComponent
  ]
})
export class DocumentTemplateModule { }
