import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusPipe } from './status.pipe';
import { TipoPipe } from './tipo.pipe'

@NgModule({
  declarations: [
    StatusPipe,
    TipoPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StatusPipe,
    TipoPipe,
  ]
})
export class PipesModule { }
