import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VincularAitComponent } from './vincular-ait.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelecionarAitComponent } from './selecionar-ait/selecionar-ait.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule}  from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { SelecionarProcessoComponent } from './selecionar-processso/selecionar-processo.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { SolicitarAutenticacaoModule } from 'src/app/shared/components/solicitar-autenticacao/solicitar-autenticacao.module';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    VincularAitComponent, 
    SelecionarAitComponent,
    SelecionarProcessoComponent,
  ],
  imports: [
    CommonModule,
    CdkStepperModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    SolicitarAutenticacaoModule,
    MatSelectModule,
  ]
})
export class VincularAitModule { }
