import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Observer } from "rxjs";
import { ProcessoDetalhadoService } from '../services/processo-detalhado.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ordenar-documentos',
  templateUrl: './ordenar-documentos.component.html',
  styleUrls: ['./ordenar-documentos.component.css']
})
export class OrdenarDocumentosComponent implements OnInit {

  @ViewChild('pdfViewer', { static: true }) public pdfViewer;

  listaArquivos: Array<any>;
  carregamentoLista = false;
  src;
  carregamentoPreview = false;

  acessibilidade: boolean = false;

  /* Auxiliary Variables  */
  public idFileSelected: number;

  constructor(
    public processoService: ProcessoDetalhadoService,
    public dialogRef: MatDialogRef<OrdenarDocumentosComponent>,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public listaArquivosBase64: Array<any>,
  ) {
   }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem('acessibilidade'))) {
      this.acessibilidade = true;
    }

    this.listaArquivos = this.listaArquivosBase64[0].map(file=>{
      return {
        id: file.id,
        nomeArquivo: file.nomeArquivo
      }
    })

    this.previewPdf(this.listaArquivosBase64[0][0].id)
  }

  modoAcessibilidade(){
    this.acessibilidade = !this.acessibilidade;
    const acessibilidadeModeState = JSON.stringify(this.acessibilidade);
    if(this.acessibilidade) {
      localStorage.setItem('acessibilidade', acessibilidadeModeState);
    } else {
      localStorage.setItem('acessibilidade', acessibilidadeModeState);
    }
  }

  previewPdf(id){
    this.idFileSelected = id;
    this.carregamentoPreview = true;
    this.processoService.arquivoBase64(id).subscribe((res:any)=>{
      this.pdfBase64toBlob(res[0].base64).subscribe(file=>{
        this.carregamentoPreview = false;
        this.pdfViewer.pdfSrc = file;
        this.pdfViewer.refresh();
       })
    })
  }

  salvarOrdenacao(){
    this.carregamentoLista = true;
    // console.table(this.listaArquivos);
    // console.table(this.formatoRequisicao(this.listaArquivos));
    this.autenticacao.abrirDialogo()
      .subscribe(resposta => {
        if (resposta) {
          this.processoService.ordernarArquivos(this.formatoRequisicao(this.listaArquivos), this.listaArquivosBase64[1])
            .subscribe(res=>{
              this.snackBarNotification('Ordenação salva com sucesso!','sucesso');
              this.carregamentoLista = false;
              console.log(res);
              this.dialogRef.close(true);
            },
            error=>{
              this.snackBarNotification(error.error.message,'erro');
              this.carregamentoLista = false;
            }
          )
        } else {
          this.carregamentoLista = false;
        }
      }
    )
  }

  formatoRequisicao(arquivos){
    return [...arquivos].map((objeto, index)=>{
      return {
        idArquivo: objeto.id,
        ordem: index + 1
      }
    })
  }

  fecharModal() {
    console.table(this.listaArquivos)

    console.log(this.listaArquivosBase64)

    this.dialogRef.close();
  }

  position(row){
    return this.listaArquivos.indexOf(row) + 1
  }

  drop(event) {
    console.log(event);
    moveItemInArray(this.listaArquivos, event.previousIndex, event.currentIndex);
  }

  fileBase64(id){
    return this.listaArquivosBase64[0].find(file=>file.id === id).arquivo;
  }

  pdfBase64toBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<any>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      observer.next(int8Array);
      observer.complete();
    });
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  subirLista(event){
    console.log(this.listaArquivos.indexOf(event));
    const index = this.listaArquivos.indexOf(event);
    if(index!==0){
      this.drop({
        previousIndex: index,
        currentIndex: index-1
      })
    }
  }

  descerLista(event){
    console.log(this.listaArquivos.indexOf(event));
    const index = this.listaArquivos.indexOf(event);
    if(this.listaArquivos.length!==index){
      this.drop({
        previousIndex: index,
        currentIndex: index+1
      })
    }
  }

}
