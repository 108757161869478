import { Component, Inject } from "@angular/core";
import { ProcessoSelecionado } from "src/app/shared/models/processo-selecionado.model";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'lista-processos-selecionados.component',
    templateUrl: 'lista-processos-selecionados.component.html',
    styleUrls: ["./lista-processos-selecionados.component.css"]
  })
  export class ListaProcessosSelecionadosComponent {
    constructor(
      public dialogRef: MatDialogRef<ListaProcessosSelecionadosComponent>,
      @Inject(MAT_DIALOG_DATA) public processosSelecionados: Array<ProcessoSelecionado>,
    ) { }
  
    displayedColumns: string[] = ['protocolo', 'name', 'delete'];
  
    fecharModal() {
      this.dialogRef.close(this.processosSelecionados);
    }
  
    deleteProcessList(row) {
      this.processosSelecionados = this.processosSelecionados.filter(
        processo => processo.id !== row.id
      )
      if(this.processosSelecionados.length === 0) this.fecharModal();

    }
  
}