export class DocumentTemplate {
	id: number;
	moduloId: number;
	name: string;
	descriprion: string;
	template: Blob;
	header: Blob;
	footer: Blob;
	status?: string;
	action?: string;
}

