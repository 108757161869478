import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { MovimentarProcessoComponent } from 'src/app/shared/components/movimentar-processo/movimentar-processo.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MovimentarProcessoService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(processosSelecionados){
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(MovimentarProcessoComponent, {data: processosSelecionados})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }
}

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { MovimentarProcessoModule } from 'src/app/shared/components/movimentar-processo/movimentar-processo.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { MovimentarProcessoService } from 'src/app/shared/services/movimentar-processo.service';

      constructor(public movimentarProcesso: MovimentarProcessoService) { }
*/