import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs';
import { DocumentFormComponent } from 'src/app/modules/document-template/document/document-form/document-form.component';

@Injectable({
  providedIn: 'root'
})

export class GerarDocumentoService {

constructor(public dialog: MatDialog) { }

abrirDialogo(idProcesso) {
    const referenciaDialogo = this.dialog.open(DocumentFormComponent, {
        disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
        data: idProcesso
    });
    return new Observable(observavel => {
        referenciaDialogo.afterClosed().subscribe(value => {
            observavel.next(value)
            observavel.complete();
        })
    })
}

}