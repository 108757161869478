import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('engine:engine')
  })
};

@Injectable({
  providedIn: 'root'
})
export class VincularAitService {

  private usuarioData = JSON.parse(localStorage.getItem('usuarioData'));

  constructor(
    private http: HttpClient,
    ) { }

  listarAits(dataInicio, dataFim, skip: number, take: number) {
    return this.http.get(`${environment.apiTalonario}listarAitsSGI?dataInicio=${dataInicio}&dataFim=${dataFim}&skip=${skip}&take=${take}`,
      httpOptions);
  }

  listarProcessos(pag: number, quantidade: number, from: string, to: string, filter: string, input: string) {
    filter === "" ? filter = "" : filter = `&filter=${filter}`;
    input === "" ? input = "" : input = `&input=${input}`;
    return this.http.get(`${environment.caminhoApi}processos-nao-vinculados-ait/?pag=${pag}&qtd=${quantidade}&from=${from}&to=${to}${filter}${input}`, httpOptions);
  }

  vincularAIT(aitProcesso) {
    return this.http.post(`${environment.caminhoApi}vincular-processo-ait`,aitProcesso, httpOptions);
  }
}
