import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { DocumentTemplateFormComponent } from '../document-template-form/document-template-form.component';
import { DocumentTemplate } from '../model/document-template.model';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as htmlToPdfMake from 'html-to-pdfmake';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DocumentTemplateService } from '../service/document-template.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentTemplateConfig } from '../model/document-template-config.model';
import { environment } from 'src/environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-document-template-config',
  templateUrl: './document-template-config.component.html',
  styleUrls: ['./document-template-config.component.css']
})
export class DocumentTemplateConfigComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      // ['blockquote', 'code-block'],
      // [{ list: 'ordered' }, { list: 'bullet' }],
      // ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  public htmlContent: any;
  public htmlContentHeader: any;
  public htmlContentFooter: any;
  public htmlContentWaterMark: any;

  usuarioData: UsuarioData;
  carregandoCadastro: boolean = false;
  documentTemplateConfigForm: FormGroup;
  isLinear = false;
  documentConfig: DocumentTemplateConfig;

  logoCliente = environment.logoCliente;


  constructor(
    @Inject(MAT_DIALOG_DATA) public documentTemplateEditar: DocumentTemplate,
    private dialogRef: MatDialogRef<DocumentTemplateFormComponent>,
    private fb: FormBuilder,
    private documentTemplateService: DocumentTemplateService,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  }

  ngOnInit(): void {
    this.documentTemplateConfigForm = this.fb.group({
      id: [0],
      moduloId: [this.usuarioData.modulo.id],
      header: [''],
      footer: [''],
      waterMark: [''],
    })
    this.carregar();
  }

  closeModal() {
    this.carregandoCadastro = false;
    this.dialogRef.close();
  }


  onSubmit() {
    if (this.documentTemplateConfigForm.valid) {
      this.save(this.documentTemplateConfigForm.value);
    }
  }

  save(data) {
    this.carregandoCadastro = true;
    this.autenticacao.abrirDialogo().subscribe(response => {
      if (response) {

        const encoder = new TextEncoder();
        const headerBytes = encoder.encode(this.htmlContentHeader);
        const footerBytes = encoder.encode(this.htmlContentFooter);
        const waterMarkBytes = encoder.encode(this.htmlContentWaterMark);
        const headerBase64 = btoa(String.fromCharCode.apply(null, headerBytes));
        const footerBase64 = btoa(String.fromCharCode.apply(null, footerBytes));
        const waterMarkBase64 = btoa(String.fromCharCode.apply(null, waterMarkBytes));

        data.header = headerBase64;
        data.footer = footerBase64;
        data.waterMark = waterMarkBase64;
        data.moduloId = this.usuarioData.modulo.id;

        this.documentTemplateService.saveConfig(data).subscribe(result => {
          this.carregandoCadastro = false;
          this.snackBarNotification('Configuração Template de Documento realizado com sucesso!', 'sucesso');
          this.dialogRef.close(true);
        },
          err => {
            this.carregandoCadastro = false;
            this.snackBarNotification(err.error.message, 'erro');
          })

      } else {
        this.carregandoCadastro = false;
      }
    });
  }

  carregar() {
    this.carregandoCadastro = true;
    this.documentTemplateService.getConfig(this.usuarioData.modulo.id).subscribe(result => {

      this.documentConfig = result;

      const headerBytes = atob(String(result.header));
      const footerBytes = atob(String(result.footer));
      const waterMarkBytes = atob(String(result.waterMark));
      const headerArrayBuffer = this.stringToArrayBuffer(headerBytes);
      const footerArrayBuffer = this.stringToArrayBuffer(footerBytes);
      const waterMarkArrayBuffer = this.stringToArrayBuffer(waterMarkBytes);
      const headerString = new TextDecoder().decode(headerArrayBuffer);
      const footerString = new TextDecoder().decode(footerArrayBuffer);
      const waterMarkString = new TextDecoder().decode(waterMarkArrayBuffer);
      this.htmlContentHeader = headerString;
      this.htmlContentFooter = footerString;
      this.htmlContentWaterMark = waterMarkString;

      this.documentTemplateConfigForm.patchValue({
        id: result.id,
        moduloId: result.moduloId,
        header: headerString,
        footer: footerString,
        waterMark: waterMarkString,
      })
      this.updatePreview();

      this.carregandoCadastro = false;
    }, err => {
      this.carregandoCadastro = false;
      // this.snackBarNotification(err.error.message, 'erro');
    })
  }

  stringToArrayBuffer(str: string): ArrayBuffer {
    const buffer = new ArrayBuffer(str.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < str.length; i++) {
      bufferView[i] = str.charCodeAt(i);
    }
    return buffer;
  }

  onChangedEditor(event: any): void {
    if (event.html) {
      this.htmlContent = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  onChangedEditorHeader(event: any): void {
    if (event.html) {
      this.htmlContentHeader = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  onChangedEditorFooter(event: any): void {
    if (event.html) {
      this.htmlContentFooter = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  onChangedEditorWaterMark(event: any): void {
    if (event.html) {
      this.htmlContentWaterMark = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  updatePreview() {
    // Atualiza o conteúdo do preview usando o HTML atual do Quill
    const previewElement = document.getElementById('preview');
    if (previewElement) {
      previewElement.innerHTML = this.htmlContentHeader;
      previewElement.innerHTML = this.htmlContentFooter;
      previewElement.innerHTML = this.htmlContentWaterMark;
    }

    this.carregarPdf();

  }

  async getImageDataUrl(imageUrl: string): Promise<string> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        resolve(imageDataUrl);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async generatePDF() {

    const imageUrl = this.logoCliente;
    const imageDataUrl = await this.getImageDataUrl(imageUrl);
    const image = new Image();
    image.src = imageDataUrl;
    await image.decode(); // Esperar a imagem ser carregada

    const imageWidth = 300;
    const imageHeight = (image.height * imageWidth) / image.width;


    // Converte o conteúdo HTML para o formato do pdfMake
    const pdfMakeContentHeader = htmlToPdfMake(this.htmlContentHeader);
    const pdfMakeContentFooter = htmlToPdfMake(this.htmlContentFooter);
    const pdfMakeContentWaterMark = htmlToPdfMake(this.htmlContentWaterMark);

    // Define a definição do documento PDF
    const docDefinition = {
      pageSize: 'A4',
      header: { text: pdfMakeContentHeader, style: 'header' },
      footer: { text: pdfMakeContentFooter, style: 'footer' },
      content: [
      ],
      background: [
        {
          image: imageDataUrl, // O dataURL da imagem da marca d'água
          width: imageWidth, // Largura da imagem da marca d'água
          height: imageHeight, // Altura da imagem da marca d'água
          opacity: 0.25, // Opacidade da marca d'água (0 a 1)
          absolutePosition: { x: 150, y: 200 } // Posição absoluta da marca d'água no PDF
        }
      ],
      styles: {
        header: { fontSize: 12, margin: [10, 10, 10, 0] },
        footer: { fontSize: 12, margin: [10, 0, 10, 10] },
        preview: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
        // 'ql-align-center':  { text-align: center },
        'ql-align-justify': { alignment: 'justify' },
        'ql-align-center': { alignment: 'center' },
        'ql-align-left': { alignment: 'left' },
        'ql-align-right': { alignment: 'right' },
        'ql-size-small': { fontSize: '9' },
        'ql-size-large': { fontSize: '24' },
        'ql-size-huge': { fontSize: '32' }
      },
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);

    return pdfDoc;

  }

  async carregarPdf() {

    const pdfDoc = await this.generatePDF();
    pdfDoc.getDataUrl((dataUrl) => {
      const pdfContainer = document.getElementById('pdfContainer');
      pdfContainer.innerHTML = `<iframe src="${dataUrl}" width="100%" height="100%"></iframe>`;
    });

  }

  async abrirPdf() {

    const pdfDoc = await this.generatePDF();

    pdfDoc.open()

  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
