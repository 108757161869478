import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentTemplateService } from '../../service/document-template.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentTemplate } from '../../model/document-template.model';
import { MatDialog } from '@angular/material/dialog';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

import { QuillEditorComponent } from 'ngx-quill';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as htmlToPdfMake from 'html-to-pdfmake';
import { DocumentTemplateConfig } from '../../model/document-template-config.model';
import { environment } from 'src/environments/environment';
import { ProcessoDetalhadoService } from 'src/app/shared/components/processo-detalhado/services/processo-detalhado.service';
import { formatDate } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.css']
})
export class DocumentFormComponent implements OnInit {

  @ViewChild('quillRef', { static: true }) quillEditor!: QuillEditorComponent;

  @ViewChild('pdfTable') pdfTable: ElementRef;


  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      // ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  public htmlContent: any;
  public htmlContentHeader: any;
  public htmlContentFooter: any;
  public htmlContentWaterMark: any;

  carregando: boolean = false;
  arquivosNovosAdicionados: boolean = false;
  usuarioData: UsuarioData;
  documentTemplates: DocumentTemplate[] = [];
  documentTemplate: DocumentTemplate;
  conteudoTemplate: any; // Altere o tipo de Blob para any
  nameDocument = '';
  pdfBlob;
  arquivos = [];

  documentConfig: DocumentTemplateConfig;

  logoCliente = environment.logoCliente;

  variaveis = {
    protocolo: '', //id
    especieRecurso: '', //enumTipoProcesso
    prioridade: '', //enumPrioridade
    uf: '', //uf
    dataProcesso: '', //dataCadastro
    dataDocumento: '',
    autoInfracao: '', //numAIT
    numeroDocumento: '', //numProcessoOriginal
    nomeInteressado: '', //interessado
    dataAtual: ''
  };

  carregamentoDados: boolean = true;
  carregamentoInteressado: boolean = true;
  dadosProcesso;
  dadosCarregados: boolean = false;

  exibirDiv: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DocumentFormComponent>,
    private documentTemplateService: DocumentTemplateService,
    private notificacao: MatSnackBar,
    private dialog: MatDialog,
    private autenticacao: SolicitarAutenticacaoService,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public idProcesso,
    public processoService: ProcessoDetalhadoService,
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  }



  ngOnInit(): void {
    this.listarDocumentos(this.usuarioData.modulo.id);
    this.carregarConfig();
    this.detalharProcesso();
  }

  detalharProcesso() {
    this.carregamentoDados = true;
    this.carregamentoInteressado = true;
    
    this.processoService.detalharProcesso(this.idProcesso).subscribe(
      (res) => {
        console.log(res)
        this.dadosProcesso = res;
        this.carregamentoDados = false;
        this.carregamentoInteressado = false;
        this.dadosCarregados = true;

        this.variaveis.protocolo = this.dadosProcesso.numeroProtocolo;
        let TipoProcesso;
        if (this.dadosProcesso.enumTipoProcesso === 'DEFESA_AUTUACAO') {
          TipoProcesso = 'Defesa de autuação'
        } else if (this.dadosProcesso.enumTipoProcesso === 'RECURSO') {
          TipoProcesso = 'Recurso'
        } else if (this.dadosProcesso.enumTipoProcesso === 'COMUNICACAO_VENDA') {
          TipoProcesso = 'Comunicação de venda'
        } else if (this.dadosProcesso.enumTipoProcesso === 'TRANSFERENCIA_PONTUACAO') {
          TipoProcesso = 'Transferência de pontuação'
        }
        this.variaveis.especieRecurso = TipoProcesso;
        this.variaveis.prioridade = this.dadosProcesso.enumPrioridade;
        this.variaveis.uf = this.dadosProcesso.uf;
        this.variaveis.dataProcesso = formatDate(this.dadosProcesso.dataCadastro, 'dd/MM/yyyy', 'en-US');
        this.variaveis.dataDocumento = formatDate(this.dadosProcesso.dataDocumento, 'dd/MM/yyyy', 'en-US');
        this.variaveis.autoInfracao = this.dadosProcesso.numAIT;
        this.variaveis.numeroDocumento = this.dadosProcesso.numProcessoOriginal;
        this.variaveis.nomeInteressado = this.dadosProcesso.interessado;
        this.variaveis.dataAtual = formatDate(new Date(), 'dd/MM/yyyy', 'en-US');
      },
      (erro) => {
        //console.log(erro)
      }
    );
  }

  toggleDiv() {
    this.exibirDiv = !this.exibirDiv;
  } 

  listarDocumentos(moduloId: number): void {
    this.documentTemplateService.getAll(moduloId).subscribe(result => {
      this.documentTemplates = result.filter(item => item.status === 'true');
    }, (error) => {
      this.snackBarNotification('Erro ao carregar Templates', 'erro');
      console.log(error)
    })
  }

  stringToArrayBuffer(str: string): ArrayBuffer {
    const buffer = new ArrayBuffer(str.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < str.length; i++) {
      bufferView[i] = str.charCodeAt(i);
    }
    return buffer;
  }

  carregarTemplate(templateId: number) {
    this.documentTemplateService.getOne(templateId).subscribe(result => {
      this.documentTemplate = result;

      if (this.documentTemplate) {
        const templateBytes = atob(String(result.template)); // Decodifica o conteúdo Base64 para bytes
        const templateArrayBuffer = this.stringToArrayBuffer(templateBytes); // Converte os bytes para ArrayBuffer
        const templateString = new TextDecoder().decode(templateArrayBuffer); // Decodifica o ArrayBuffer para string
        this.htmlContent = templateString; // Atribui a string ao this.htmlContent

        this.conteudoTemplate = templateString;
        // Carregue o conteúdo no quill-editor usando o método insertText
        if (this.quillEditor) {
          this.quillEditor.quillEditor.insertText(0, this.conteudoTemplate);
        }

        let headerString;
        let footerString;
        if (result.header == null) {
          headerString = this.htmlContentHeader;
          this.htmlContentHeader = headerString;
        } else {
          const headerBytes = atob(String(result.header));
          const headerArrayBuffer = this.stringToArrayBuffer(headerBytes);
          headerString = new TextDecoder().decode(headerArrayBuffer);
          this.htmlContentHeader = headerString;
        }

        if (result.footer == null) {
          footerString = this.htmlContentFooter;
          this.htmlContentFooter = footerString;
        } else {
          const footerBytes = atob(String(result.footer));
          const footerArrayBuffer = this.stringToArrayBuffer(footerBytes);
          footerString = new TextDecoder().decode(footerArrayBuffer);
          this.htmlContentFooter = footerString;
        }
      }

    })
  }

  fecharModal() {
    this.dialogRef.close(this.arquivosNovosAdicionados)
  }

  publicar_DOWNLOAD() {
    this.autenticacao.abrirDialogo().subscribe(response => {
      if (response) {
        // Obtém o conteúdo da div que será usado para gerar o PDF
        const previewDiv = document.getElementById('preview');
        const pdfContent = previewDiv.innerHTML;

        // Converte o conteúdo HTML para o formato do pdfMake
        const pdfMakeContent = htmlToPdfMake(pdfContent);

        // Define o conteúdo do documento PDF usando pdfMake
        const docDefinition = {
          content: [
            // Você pode adicionar outros elementos antes ou depois da tabela aqui, se necessário
            { text: 'Título do Documento', style: 'header' },
            { text: 'Outros conteúdos do documento', style: 'subheader' },
            pdfMakeContent // Insere o conteúdo convertido no PDF
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10]
            },
            subheader: {
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 5]
            },
            tableStyle: {
              margin: [0, 5, 0, 15]
            }
          }
        };

        // Gera o arquivo PDF
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('nome_do_arquivo.pdf');
      }
    });
  }

  publicar() {
    this.autenticacao.abrirDialogo().subscribe(async response => {
      if (response) {

        const imageUrl = this.logoCliente;
        const imageDataUrl = await this.getImageDataUrl(imageUrl);
        const image = new Image();
        image.src = imageDataUrl;
        await image.decode(); // Esperar a imagem ser carregada

        const imageWidth = 300;
        const imageHeight = (image.height * imageWidth) / image.width;


        // Converte o conteúdo HTML para o formato do pdfMake
        const pdfMakeContentHeader = htmlToPdfMake(this.htmlContentHeader);
        const pdfMakeContentFooter = htmlToPdfMake(this.htmlContentFooter);
        const pdfMakeContentWaterMark = htmlToPdfMake(this.htmlContentWaterMark);

        // Converte o conteúdo HTML para o formato do pdfMake
        const pdfMakeContent = htmlToPdfMake(this.htmlContent);

        // Função para substituir variáveis no texto
        function replaceVariablesInText(text, variaveis) {
          if (typeof text === 'string') {
            // Use uma expressão regular para identificar e substituir todas as variáveis no texto
            for (const variavel in variaveis) {
              if (variaveis.hasOwnProperty(variavel)) {
                const regex = new RegExp('\\$' + variavel, 'g');
                text = text.replace(regex, variaveis[variavel].toString());
              }
            }
          } else if (Array.isArray(text)) {
            // Se for um array, percorra os itens recursivamente
            text = text.map(item => replaceVariablesInText(item, variaveis));
          } else if (typeof text === 'object') {
            // Se for um objeto, percorra as propriedades recursivamente
            for (const key in text) {
              if (text.hasOwnProperty(key)) {
                text[key] = replaceVariablesInText(text[key], variaveis);
              }
            }
          }
          return text;
        }

        // Substitui as variáveis no conteúdo HTML usando o objeto de variáveis
        const pdfMakeContentWithVariables = replaceVariablesInText(pdfMakeContent, this.variaveis);

        // Define o conteúdo do documento PDF usando pdfMake
        const docDefinition = {
          pageSize: 'A4',
          header: { text: pdfMakeContentHeader, style: 'header' },
          footer: { text: pdfMakeContentFooter, style: 'footer' },
          content: [
            pdfMakeContentWithVariables
          ],
          background: [
            {
              image: imageDataUrl, // O dataURL da imagem da marca d'água
              width: imageWidth, // Largura da imagem da marca d'água
              height: imageHeight, // Altura da imagem da marca d'água
              opacity: 0.25, // Opacidade da marca d'água (0 a 1)
              absolutePosition: { x: 150, y: 200 } // Posição absoluta da marca d'água no PDF
            }
          ],
          styles: {
            header: { fontSize: 12, margin: [10, 10, 10, 0] },
            footer: { fontSize: 12, margin: [10, 0, 10, 10] },
            'ql-align-justify': { alignment: 'justify' },
            'ql-align-center': { alignment: 'center' },
            'ql-align-left': { alignment: 'left' },
            'ql-align-right': { alignment: 'right' },
            'ql-size-small': { fontSize: '9' },
            'ql-size-large': { fontSize: '24' },
            'ql-size-huge': { fontSize: '32' }
          },
        };

        // Gera o arquivo PDF
        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        // Gera o arquivo Blob a partir do conteúdo do PDF
        pdfDocGenerator.getBlob((pdfBlob: Blob) => {
          // Usa FileReader para converter o Blob em uma string base64
          const reader = new FileReader();
          reader.onload = (event) => {
            const pdfContentBase64 = event.target.result as string;
            const nomeDocumento = this.formatarTexto(this.nameDocument);
            const dataHora = this.formatarDataHora();
            const fileName = nomeDocumento ? nomeDocumento : dataHora;

            // Monta o array de objeto JSON com os dados a serem enviados para o backend
            const arquivo = [{
              assinado: false,
              descricao: 'arquivo gerado pelo DocumentTemplate',
              documento: pdfContentBase64,
              idModulo: this.usuarioData.modulo.id,
              idUsuario: this.usuarioData.idUsuario,
              // nomeArquivo: 'nome_do_arquivo.pdf'
              nomeArquivo: fileName + '.pdf'
            }];

            // Envia o array de objeto JSON para o backend usando o serviço de upload existente
            this.fileUploadService.uploadArquivo(arquivo, this.idProcesso).subscribe(
              res => {
                // Manipule a resposta do backend, se necessário
                this.carregando = false;
                this.arquivosNovosAdicionados = true;
                this.arquivos = [];
                this.fecharModal();
                this.snackBarNotification('Documento publicado com sucesso!', 'sucesso');
              },
              error => {
                this.carregando = false;
                this.snackBarNotification('Erro ao publicar Documento!', 'erro');
                console.error('Erro ao fazer o upload do arquivo:', error);
              }
            );
          };
          reader.readAsDataURL(pdfBlob);
        });
      }
    });
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  onChangedEditor(event: any): void {
    if (event.html) {
      this.htmlContent = event.html;

      // Atualiza o preview quando o conteúdo do Quill é alterado
      this.updatePreview();
    }
  }

  updatePreview() {
    // Atualiza o conteúdo do preview usando o HTML atual do Quill
    const previewElement = document.getElementById('preview');
    if (previewElement) {
      previewElement.innerHTML = this.htmlContent;
    }

    this.carregarPdf();
  }

  removerAcentos(texto: string): string {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  formatarTexto(texto: string): string {
    const textoSemAcentos = this.removerAcentos(texto);
    const textoSemCaracteresEspeciais = textoSemAcentos.replace(/[^\w\s-]/g, '');
    const textoFormatado = textoSemCaracteresEspeciais.replace(/\s+/g, '-');
    return textoFormatado.toLowerCase();
  }

  formatarDataHora(): string {
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
    const dia = dataAtual.getDate().toString().padStart(2, '0');
    const hora = dataAtual.getHours().toString().padStart(2, '0');
    const minuto = dataAtual.getMinutes().toString().padStart(2, '0');
    const segundo = dataAtual.getSeconds().toString().padStart(2, '0');
    const milissegundo = dataAtual.getMilliseconds().toString().padStart(3, '0');

    return `${ano}${mes}${dia}${hora}${minuto}${segundo}${milissegundo}`;
  }

  carregarConfig() {
    this.carregando = true;
    this.documentTemplateService.getConfig(this.usuarioData.modulo.id).subscribe(result => {
      this.documentConfig = result;

      const headerBytes = atob(String(result.header));
      const footerBytes = atob(String(result.footer));
      const waterMarkBytes = atob(String(result.waterMark));
      const headerArrayBuffer = this.stringToArrayBuffer(headerBytes);
      const footerArrayBuffer = this.stringToArrayBuffer(footerBytes);
      const waterMarkArrayBuffer = this.stringToArrayBuffer(waterMarkBytes);
      const headerString = new TextDecoder().decode(headerArrayBuffer);
      const footerString = new TextDecoder().decode(footerArrayBuffer);
      const waterMarkString = new TextDecoder().decode(waterMarkArrayBuffer);
      this.htmlContentHeader = headerString;
      this.htmlContentFooter = footerString;
      this.htmlContentWaterMark = waterMarkString;


      // this.updatePreview();
      this.carregando = false;
    })
  }

  async getImageDataUrl(imageUrl: string): Promise<string> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        resolve(imageDataUrl);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async generatePDF() {

    const imageUrl = this.logoCliente;
    const imageDataUrl = await this.getImageDataUrl(imageUrl);
    const image = new Image();
    image.src = imageDataUrl;
    await image.decode(); // Esperar a imagem ser carregada

    const imageWidth = 300;
    const imageHeight = (image.height * imageWidth) / image.width;

    // Converte o conteúdo HTML para o formato do pdfMake
    const pdfMakeContent = htmlToPdfMake(this.htmlContent);
    const pdfMakeContentHeader = htmlToPdfMake(this.htmlContentHeader);
    const pdfMakeContentFooter = htmlToPdfMake(this.htmlContentFooter);
    const pdfMakeContentWaterMark = htmlToPdfMake(this.htmlContentWaterMark);

    // Função para substituir variáveis no texto
    function replaceVariablesInText(text, variaveis) {
      if (typeof text === 'string') {
        // Use uma expressão regular para identificar e substituir todas as variáveis no texto
        for (const variavel in variaveis) {
          if (variaveis.hasOwnProperty(variavel)) {
            const regex = new RegExp('\\$' + variavel, 'g');
            text = text.replace(regex, variaveis[variavel]);
          }
        }
      } else if (Array.isArray(text)) {
        // Se for um array, percorra os itens recursivamente
        text = text.map(item => replaceVariablesInText(item, variaveis));
      } else if (typeof text === 'object') {
        // Se for um objeto, percorra as propriedades recursivamente
        for (const key in text) {
          if (text.hasOwnProperty(key)) {
            text[key] = replaceVariablesInText(text[key], variaveis);
          }
        }
      }
      return text;
    }

    // Substitui as variáveis no conteúdo HTML usando o objeto de variáveis
    const pdfMakeContentWithVariables = replaceVariablesInText(pdfMakeContent, this.variaveis);

    // Define a definição do documento PDF
    const docDefinition = {
      pageSize: 'A4',
      header: { text: pdfMakeContentHeader, style: 'header' },
      footer: { text: pdfMakeContentFooter, style: 'footer' },
      //  watermark: { text: pdfMakeContentWaterMark, color: 'blue', opacity: 0.3, bold: true, italics: false },
      content: [
        pdfMakeContentWithVariables,
      ],
      background: [
        {
          image: imageDataUrl, // O dataURL da imagem da marca d'água
          width: imageWidth, // Largura da imagem da marca d'água
          height: imageHeight, // Altura da imagem da marca d'água
          opacity: 0.25, // Opacidade da marca d'água (0 a 1)
          absolutePosition: { x: 150, y: 200 } // Posição absoluta da marca d'água no PDF
        }
      ],
      styles: {
        header: { fontSize: 12, margin: [10, 10, 10, 0] },
        footer: { fontSize: 12, margin: [10, 0, 10, 10] },
        // 'ql-align-center':  { text-align: center },
        'ql-align-justify': { alignment: 'justify' },
        'ql-align-center': { alignment: 'center' },
        'ql-align-left': { alignment: 'left' },
        'ql-align-right': { alignment: 'right' },
        'ql-size-small': { fontSize: '9' },
        'ql-size-large': { fontSize: '24' },
        'ql-size-huge': { fontSize: '32' }
      },
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);

    return pdfDoc;

  }

  async carregarPdf() {

    const pdfDoc = await this.generatePDF();
    pdfDoc.getDataUrl((dataUrl) => {
      const pdfContainer = document.getElementById('pdfContainer');
      pdfContainer.innerHTML = `<iframe src="${dataUrl}" width="100%" height="100%"></iframe>`;
    });

  }

  async abrirPdf() {

    const pdfDoc = await this.generatePDF();

    pdfDoc.open()

  }

}
