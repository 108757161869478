import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { AdministrativoComponent } from 'src/app/shared/components/administrativo/administrativo.component';

@Injectable({
  providedIn: 'root'
})
export class AdministrativoService {

  constructor(
    public dialog: MatDialog,
    ) { }

  abrirDialogo(){
    // Abre o Dialog do administrativo
    const referenciaDiaologo = this.dialog.open(AdministrativoComponent, {
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '95%',
      // width: '95%',
      autoFocus: false,
    });
  }
} 

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { AdministrativoModule } from 'src/app/shared/components/administrativo/administrativo.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { AdministrativoService } from 'src/app/shared/services/administrativo.service';

      constructor(public administrativo: AdministrativoService) { }
*/