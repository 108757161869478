import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { ModulosService } from '../services/modulos.service';
import { ListaProcessosService } from 'src/app/modules/lista-processos/services/lista-processos.service';

@Component({
  selector: "app-arquivar-processo",
  templateUrl: "./arquivar-processo.component.html",
  styleUrls: ["./arquivar-processo.component.css"]
})
export class ArquivarProcessoComponent {

  displayedColumns: string[] = ['protocolo', 'name'];

  carregandoCancelamento: boolean = false;

  descricao;

  constructor(
    public dialogRef: MatDialogRef<ArquivarProcessoComponent>,
    public _snackBar: MatSnackBar,
    public autentica: SolicitarAutenticacaoService,
    public modulosService: ModulosService,
    private listaProcessosService: ListaProcessosService,
    @Inject(MAT_DIALOG_DATA) public idProcesso
    ) { }

  arquivarProcesso(){
    this.carregandoCancelamento = true;
    this.autentica.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){

        this.modulosService.arquivarProcesso(this.idProcesso.map(x=>x.id), this.descricao).subscribe(
          res =>{
            this._snackBar.open('Processo Arquivado com Sucesso!', '', {
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['sucesso']
            });
            this.dialogRef.close(true);
            this.carregandoCancelamento = false;
            this.listaProcessosService.atualizarLista();
          },
          err =>{
            console.error(err.error.message);
            this._snackBar.open(err.error.message, '', {
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['erro']
            });
            this.carregandoCancelamento = false;
          }
        );

      } else {
        this.carregandoCancelamento = false;
      }
    })
  }
}
