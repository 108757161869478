import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastrarProcessoComponent } from './cadastrar-processo.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FileUploadModule } from 'src/app/shared/components/file-upload/file-upload.module';
import { CadastrarProcessoService } from './services/cadastrar-processo.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SolicitarAutenticacaoModule } from 'src/app/shared/components/solicitar-autenticacao/solicitar-autenticacao.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    CadastrarProcessoComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatRadioModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    SolicitarAutenticacaoModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    CadastrarProcessoService,
]
})
export class CadastrarProcessoModule { }
