import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ListaProcessosModule } from './modules/lista-processos/lista-processos.module';
import { PainelModule } from './modules/painel/painel.module';
import { CadastrarProcessoModule } from './modules/cadastrar-processo/cadastrar-processo.module';
import { ConsultarProcessoModule } from './modules/consultar-processo/consultar-processo.module';
import { VincularAitModule } from './modules/vincular-ait/vincular-ait.module';
import { LoginModule } from './modules/login/login.module';
import { AtualizarSenhaModule } from 'src/app/shared/components/atualizar-senha/atualizar-senha.module';
import { DocumentTemplateModule } from './modules/document-template/document-template.module';

import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom.date.adapter';
import { MatPaginatorIntlBr } from 'src/app/shared/translate/translate-material-component';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ManualModule } from 'src/app/shared/components/manual/manual.module';
import { AdministrativoModule } from './shared/components/administrativo/administrativo.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MandatotyFieldsModule } from './shared/components/mandatoty-fields/mandatoty-fields.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    PainelModule,
    ListaProcessosModule,
    CadastrarProcessoModule,
    ConsultarProcessoModule,
    VincularAitModule,
    LoginModule,
    AtualizarSenhaModule,
    DocumentTemplateModule,

    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    ManualModule,
    AdministrativoModule,
    MandatotyFieldsModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlBr},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
