import { Component, OnInit } from '@angular/core';
import { MandatotyFieldsService } from '../../services/mandatoty-fields.service';
import { MandatoryFields } from '../../models/mandatoty-fields';

@Component({
  selector: 'app-mandatoty-fields',
  templateUrl: './mandatoty-fields.component.html',
  styleUrls: ['./mandatoty-fields.component.css']
})
export class MandatotyFieldsComponent implements OnInit {

  mandatoryFields: MandatoryFields[] = [];

  constructor(private mandatoryFieldsService: MandatotyFieldsService) { }

  ngOnInit(): void {
    this.getMandatotyFields();
  }

  getMandatotyFields() {
    this.mandatoryFieldsService.listarCampos().subscribe(result => {
      this.mandatoryFields = result;
      console.log(this.mandatoryFields);
    }, (err) => {
      console.log(err)
    })
  }

}
