import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcessoSelecionado } from 'src/app/shared/models/processo-selecionado.model';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MarcadorService } from './services/marcador.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-marcador',
  templateUrl: './marcador.component.html',
  styleUrls: ['./marcador.component.css']
})
export class MarcadorComponent implements OnInit {

  displayedColumns: string[] = ['protocolo', 'name'];
  descricao = '';
  carregandoMarcador = false;

  constructor(
    public dialogRef: MatDialogRef<MarcadorComponent>,
    public autentica: SolicitarAutenticacaoService,
    public marcador: MarcadorService,
    private notificacao: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public processos: Array<ProcessoSelecionado>,
  ) { }

  ngOnInit(): void {
    console.table(this.processos.map(element=> (
      {
        protocolo: element.numeroProtocolo,
        nome: element.nomeInteressado
      }
    )))
  }

  marcarProcesso() {
    this.carregandoMarcador = true;
    this.autentica.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){
        this.marcador.addMarcador({
          idModulo: JSON.parse(localStorage.getItem('usuarioData')).modulo.id,
          idUsuario: JSON.parse(localStorage.getItem('usuarioData')).idUsuario,
          idsProcesso: this.processos.map(res=> {return res.id}),
          marcador: this.descricao
        }).subscribe(
          res=>{
            this.carregandoMarcador = false;
            this.dialogRef.close(true);
            this.snackBarNotification('Os marcadores foram cadastrado com sucesso!','sucesso');
          },
          error=>{
            this.carregandoMarcador = false;
            this.snackBarNotification(error.error.message, 'erro');
          })
      } else {
        this.carregandoMarcador = false;
      }
    })
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  checked = (event: MatCheckboxChange)=>{ if(event.checked) this.descricao = null };
}
