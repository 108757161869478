import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { CadastrarArquivoComponent } from 'src/app/shared/components/cadastrar-arquivo/cadastrar-arquivo.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CadastrarArquivoService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(idProcesso){
    // Abre o Dialog para detalhar processo
    const referenciaDiaologo = this.dialog.open(CadastrarArquivoComponent, {
      maxHeight: '80vw',
      data: idProcesso
    });
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed().
      subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }
}

/* Para usar esse serviço voce tem que:
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { CadastrarArquivoModule } from 'src/app/shared/components/cadastrar-arquivo/cadastrar-arquivo.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { CadastrarArquivoService } from 'src/app/shared/services/cadastrar-arquivo.service';

      constructor(public cadastrarArquivo: CadastrarArquivoService) { }
*/