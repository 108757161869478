import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MostrarBotoesComponent } from './mostrar-botoes.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MudarStatusModule } from '../mudar-status/mudar-status.module';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MudarStatusModule,
  ],
  declarations: [MostrarBotoesComponent],
  exports: [MostrarBotoesComponent]
})
export class MostrarBotoesModule { }
