import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PainelComponent } from './painel.component';
import { PainelService } from './services/painel.service';
import { PanelIndicatorComponent } from './components/panel-indicator/panel-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    PainelComponent,
    PanelIndicatorComponent
  ],
  providers: [PainelService]
})
export class PainelModule { }
