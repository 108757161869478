import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  statusEnum = {
    ABERTO: 'Aberto',
    ANDAMENTO: 'Andamento',
    ARQUIVADO: 'Arquivado',
    DEFERIDO: 'Deferido',
    INDEFERIDO: 'Indeferido',
    CANCELADO: 'Cancelado',
    SUSPENSO: 'Suspenso',
    DESCARTADO_PROTOCOLO: 'Descartado pelo Protocolo',
  }

  transform(value) {
    return this.statusEnum[value];
  }

}