import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsuarioData } from '../../models/usuarioData.model';
import { ProcessoSelecionado } from '../../models/processo-selecionado.model';

@Component({
  selector: 'app-mostrar-botoes',
  templateUrl: './mostrar-botoes.component.html',
  styleUrls: ['./mostrar-botoes.component.css']
})
export class MostrarBotoesComponent implements OnInit {

  // Necessário apenas para habilitar/desabilitar os botões
  @Input() processoSelecionado: Array<ProcessoSelecionado>;

  // Inputs que diz quais botões o componente pai possui
  @Input() deferir: boolean;
  @Input() indeferir: boolean;
  @Input() cancelar: boolean;
  @Input() arquivar: boolean;
  @Input() suspenderCnh: boolean;
  @Input() cassarCnh: boolean;

  @Input() tramitar: boolean;
  @Input() solicitarVoto: boolean;
  @Input() detalhar: boolean;

  // Emite um evento quando determinado botão é clickado,
  // para o componente pai lidar com a regra de negócio
  @Output() botaoClickado = new EventEmitter();

  // user data salva no localStorage após o login
  usuarioData: UsuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  acoesProcesso = [
    { chave: "Descartar", exibir: this.mostrarBotao('descartar') },
    { chave: "Deferir", exibir: this.mostrarBotao('deferir') },
    { chave: "Indeferir", exibir: this.mostrarBotao('indeferir') },
    { chave: "Arquivar", exibir: this.mostrarBotao('arquivar') },
    { chave: "Cancelar", exibir: this.mostrarBotao('cancelar-processo') },
  ]

  constructor() { }

  ngOnInit() {
  }

  // Método que da um find no array de funcionalidades para exibir ou não os botões.
  mostrarBotao(funcionalidade) {
    return this.usuarioData.modulo.funcionalidades.find(f => f.identificador === funcionalidade);
  }

  detalharBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'detalhar',
        nome: this.mostrarBotao('detalhar').descricao
      }
    );
  }

  deferirBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'deferir-processo',
        nome: this.mostrarBotao('deferir').descricao
      }
    );
  }

  indeferirBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'indeferir-processo',
        nome: this.mostrarBotao('indeferir').descricao
      }
    );
  }

  cancelarBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'cancelar-processo',
        nome: this.mostrarBotao('cancelar').descricao
      }
    );
  }

  arquivarBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'arquivar-processo',
        nome: this.mostrarBotao('arquivar').descricao
      }
    );
  }

  suspenderCnhBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'suspender-cnh',
        nome: this.mostrarBotao('suspender-cnh').descricao
      }
    );
  }

  cassarCnhBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'cassar-cnh',
        nome: this.mostrarBotao('cassar-cnh').descricao
      }
    );
  }

  tramitarBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'tramitar',
        nome: this.mostrarBotao('tramitar').descricao
      }
    );
  }

  solicitarVotoBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'solicitar-voto',
        nome: this.mostrarBotao('solicitar-voto').descricao
      }
    );
  }

  movimentarProcessoBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'movimentar-processo',
      }
    );
  }

  juntarProcessoBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'juntar-processo',
      }
    );
  }

  distribuirProcessoBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'distribuir-processo',
      }
    );
  }

  marcarProcessoBtn() {
    this.botaoClickado.emit(
      {
        tipo: 'marcar-processo',
      }
    );
  }

}
