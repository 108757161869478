import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MudarStatusComponent, OpcoesComponent } from './mudar-status.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { CancelarProcessoModule } from './cancelar-processo/cancelar-processo.module';
import { ArquivarProcessoModule } from './arquivar-processo/arquivar-processo.module';
import { DeferirProcessoModule } from './deferir-processo/deferir-processo.module';
import { IndeferirProcessoModule } from './indeferir-processo/indeferir-processo.module';
import { DescartarProcessoModule } from './descartar-processo/descartar-processo.module';

@NgModule({
  declarations: [
    MudarStatusComponent,
    OpcoesComponent,
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatListModule,
    CancelarProcessoModule,
    ArquivarProcessoModule,
    DeferirProcessoModule,
    IndeferirProcessoModule,
    DescartarProcessoModule,
  ],
  exports: [
    MudarStatusComponent,
    OpcoesComponent,
  ],
})
export class MudarStatusModule { }
