import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipo'
})
export class TipoPipe implements PipeTransform {

  tipoEnum = {
    DEFESA_AUTUACAO: 'Defesa de autuação',
    RECURSO: 'Recurso',
    COMUNICACAO_VENDA: 'Comunicação de venda',
    TRANSFERENCIA_PONTUACAO: 'Transferência de pontuação',
  }

  transform(value) {
    return this.tipoEnum[value];
  }

}