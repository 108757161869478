import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescartarProcessoComponent } from './descartar-processo.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { LoginService } from 'src/app/modules/login/service/login-provider';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { SolicitarAutenticacaoModule } from 'src/app/shared/components/solicitar-autenticacao/solicitar-autenticacao.module';
import { ModulosService } from '../services/modulos.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    MatSnackBarModule,
    MatSelectModule,
    SolicitarAutenticacaoModule,
    MatProgressSpinnerModule,
    MatProgressSpinnerModule,
    MatTableModule,
  ],
  declarations: [
    DescartarProcessoComponent, 
  ],
  exports: [
    DescartarProcessoComponent
  ],
  providers: [
    LoginService,
    ModulosService,
  ]
})
export class DescartarProcessoModule { }
