import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CancelarProcessoComponent } from './cancelar-processo/cancelar-processo.component';
import { ArquivarProcessoComponent } from './arquivar-processo/arquivar-processo.component';
import { DeferirProcessoComponent } from './deferir-processo/deferir-processo.component';
import { IndeferirProcessoComponent } from './indeferir-processo/indeferir-processo.component';
import { DescartarProcessoComponent } from './descartar-processo/descartar-processo.component';
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mudar-status',
  templateUrl: './mudar-status.component.html',
  styleUrls: ['./mudar-status.component.css']
})
export class MudarStatusComponent {

  @Input() idProcesso;
  @Input() status;
  @Input() acoesProcesso;
  @Input() lote;

  constructor(private _bottomSheet: MatBottomSheet) { }

  abrirOpcoes(): void {
    this._bottomSheet.open(OpcoesComponent, {
      data: {
        idProcesso: this.idProcesso,
        status: this.status,
        acoesProcesso: this.acoesProcesso
      }
    });
  }
}

@Component({
  selector: 'app-opcoes',
  templateUrl: './opcoes.component.html',
})
export class OpcoesComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<OpcoesComponent>,
    public dialog: MatDialog,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    ) { }

  cancelarProcesso(){
    this._bottomSheetRef.dismiss();
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(CancelarProcessoComponent, {data: this.data.idProcesso})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }

  arquivarProcesso(){
    this._bottomSheetRef.dismiss();
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(ArquivarProcessoComponent, {data: this.data.idProcesso})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }

  deferirProcesso(){
    this._bottomSheetRef.dismiss();
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(DeferirProcessoComponent, {data: this.data.idProcesso})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }

  indeferirProcesso(){
    this._bottomSheetRef.dismiss();
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(IndeferirProcessoComponent, {data: this.data.idProcesso})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }

  descartarProcesso(){
    this._bottomSheetRef.dismiss();
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(DescartarProcessoComponent, {data: this.data.idProcesso})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }

  acaoProcesso(acao: string){
    return this.data.acoesProcesso.find(item => item.chave === acao).exibir;
  }
}
