import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { CadastrarProcessoService } from './services/cadastrar-processo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MandatotyFieldsService } from 'src/app/shared/services/mandatoty-fields.service';
import { MandatoryFields } from "src/app/shared/models/mandatoty-fields";

import { debounceTime, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: "app-cadastrar-processo",
  templateUrl: "./cadastrar-processo.component.html",
  styleUrls: ["./cadastrar-processo.component.css"]
})
export class CadastrarProcessoComponent implements OnInit {

  protocoloSuggestions: string[] = [];


  ufs: String[] = [
    "PA",
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO"
  ];
  cadastrarProcessoForm: FormGroup;
  tiposProcesso = [
    { valor: 'DEFESA_AUTUACAO', nome: 'Defesa Autuação' },
    { valor: 'RECURSO', nome: 'Recurso' },
    { valor: 'RECURSO_2_INSTANCIA', nome: 'Recurso 2ª Instância' },
    // { valor: 'COMUNICACAO_VENDA', nome: 'Comunicação de Venda' },
    // { valor: 'TRANSFERENCIA_PONTUACAO', nome: 'Transferência de Pontuação' }
  ];
  carregandoLogin: boolean = false;
  moduloIdDestino: number;
  usuarioData: UsuarioData;
  arquivosEnvio;
  mandatoryFields: MandatoryFields[] = [];

  validarCampoEmail: any = Validators.required;
  validarCampoTelefone: any = Validators.required;
  validarCampoDataNasc: any = Validators.required;


  constructor(
    private cadastrarProcessoService: CadastrarProcessoService,
    private formBuilder: FormBuilder,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
    private router: Router,
    private mandatoryFieldsService: MandatotyFieldsService
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.cadastrarProcessoForm = this.formBuilder.group(
      {
        arquivos: null,
        complemento: [''],
        // tslint:disable-next-line:max-line-length
        cpfInteressado: ['',[Validators.required,Validators.pattern(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/)]],
        // dthNascimento: ['', this.validarCampoDataNasc],
        dthNascimento: [''],
        // email: ['', this.validarCampoEmail],
        email: [''],
        enumPrioridade: ['', Validators.required],
        enumTipoPessoa: ['NATURAL', Validators.required],
        enumTipoProcesso: ['', Validators.required],
        // numProcessoAnterior: ['', Validators.required],
        numProcessoAnterior: [''],
        idModulo: [this.usuarioData.modulo.id],
        idUsuario: [this.usuarioData.idUsuario],
        nomeInteressado: ['', Validators.required],
        numProcessoOriginal: [''],
        // telefoneInteressado: ['', Validators.required],
        telefoneInteressado: [''],
        uf: ['', Validators.required],
        dataDocumento: ['', Validators.required],
        numAIT: [''],
        placa: ['', Validators.required],
      });
  }

  ngOnInit() {
    this.getMandatotyFields();
    console.log(this.usuarioData)

    // Escuta mudanças no campo numProcessoAnterior
    this.cadastrarProcessoForm.get('numProcessoAnterior')!.valueChanges
    .pipe(
      debounceTime(300), // Espera 300ms após o usuário parar de digitar
      switchMap(value => {
        if (value && value.length >= 3) {
          return this.cadastrarProcessoService.searchProcessos(value);
        } else {
          return of([]); 
        }
      }),
      catchError(() => of([])) // Em caso de erro, retorna um array vazio
    )
    .subscribe(processos => {
      if (Array.isArray(processos)) {
        this.protocoloSuggestions = processos.map(p => p.numeroProtocolo);
      } else {        
        console.error('processos não é um array:', processos);
      }
      console.log('Processos encontrados:', this.protocoloSuggestions);
    });
  }
  
  getMandatotyFields(): void {
    this.cadastrarProcessoForm.get('email').setValidators([Validators.required]);
    this.cadastrarProcessoForm.get('telefoneInteressado').setValidators([Validators.required]);
    this.cadastrarProcessoForm.get('dthNascimento').setValidators([Validators.required]);
    this.cadastrarProcessoForm.get('numAIT').setValidators([Validators.required]);
    this.cadastrarProcessoForm.get('placa').setValidators([Validators.required, Validators.minLength(7),Validators.maxLength(7)]);

    this.mandatoryFieldsService.listarCampos().subscribe(response => {
      this.validarCampoEmail = Validators.nullValidator;
      this.mandatoryFields = response;
      console.log(this.mandatoryFields);

      // Percorre a lista e define os validadores de acordo com o campo obrigatório
      for (const field of this.mandatoryFields) {
        if (field.mandatory === 'false') {
          switch (field.fieldName) {
            case 'email':
              field.mandatory = 'validar';
               this.cadastrarProcessoForm.get('email').setValidators([Validators.nullValidator]);
              break;
            case 'telefone':
              field.mandatory = 'validar';
              this.cadastrarProcessoForm.get('telefoneInteressado').setValidators([Validators.nullValidator]);
              break;
            case 'dth_nascimento':
              field.mandatory = 'validar';
              this.cadastrarProcessoForm.get('dthNascimento').setValidators([Validators.nullValidator]);
              break;
            case 'numAIT':
              field.mandatory = 'validar';
              this.cadastrarProcessoForm.get('numAIT').setValidators([Validators.nullValidator]);
              break;
            case 'placa':
              field.mandatory = 'validar';
              this.cadastrarProcessoForm.get('placa').setValidators([Validators.nullValidator]);
              break;              
            // Adicione mais casos conforme necessário para outros campos
          }
        }
      }

      // Após configurar os validadores, atualize os controles no formulário
    this.cadastrarProcessoForm.get('email').updateValueAndValidity();
    this.cadastrarProcessoForm.get('telefoneInteressado').updateValueAndValidity();
    this.cadastrarProcessoForm.get('dthNascimento').updateValueAndValidity();
    this.cadastrarProcessoForm.get('numAIT').updateValueAndValidity();
    this.cadastrarProcessoForm.get('placa').updateValueAndValidity();

    }, (err) => {
      console.log(err)
    })
  }

  get f() { return this.cadastrarProcessoForm.controls; }

  onSubmit() {
    if (this.cadastrarProcessoForm.valid) {      
      this.cadastrarProcesso(this.cadastrarProcessoForm.value);
    }    
  }

  cadastrarProcesso(processo) {
    this.carregandoLogin = true;
    processo.arquivos = this.arquivosEnvio;
    console.log(processo)
    this.autenticacao.abrirDialogo()
    .subscribe(resposta =>{
      if(resposta){

        this.cadastrarProcessoService.cadastrarProcesso(processo)
        .subscribe(
          res => {
            this.cadastrarProcessoForm.reset();
            this.carregandoLogin = false;
            this.snackBarNotification('Processo cadastrado com sucesso!','sucesso');
            this.router.navigate(['/lista-processos']);
            console.log(processo);
          },
          err => {
            this.carregandoLogin = false;
            this.snackBarNotification(err.error.message, 'erro');
          }
        );

      } else {
        this.carregandoLogin = false;
      }
    })
  }

  onNumProcessoAnteriorInput() {
    // Pode ser usado para outras lógicas ao digitar no campo, se necessário
    console.log('onNumProcessoAnteriorInput');
  }

  atualizarListaArquivosUpload(event){

    this.arquivosEnvio = event;
    console.log(event);
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    }
  );
   
    
  }

  
}
