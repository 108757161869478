import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessoDetalhadoComponent } from './processo-detalhado.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { LoginService } from 'src/app/modules/login/service/login-provider';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CadastrarArquivoModule } from 'src/app/shared/components/cadastrar-arquivo/cadastrar-arquivo.module';
import { MudarStatusModule } from '../mudar-status/mudar-status.module';
import { MatChipsModule } from '@angular/material/chips';
import { ProcessoDetalhadoService } from './services/processo-detalhado.service';
import { OrdenarDocumentosComponent } from './ordenar-documentos/ordenar-documentos.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { VisualizarArquivoComponent } from './visualizar-arquivo/visualizar-arquivo.component';
import { RetificarProcessoComponent } from './retificar-processo/retificar-processo.component';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { ValidarArquivoModule } from 'src/app/shared/components/validar-arquivo/validar-arquivo.module';
import { VotoComponent } from './voto/voto.component';
import { QuillModule } from 'ngx-quill';
import { MatRadioModule } from '@angular/material/radio';
import { FileUploadModule } from '../file-upload/file-upload.module';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    CadastrarArquivoModule,
    MudarStatusModule,
    MatChipsModule,
    DragDropModule,
    PdfJsViewerModule,
    MatSelectModule,
    MatInputModule,
    PipesModule,
    MatSlideToggleModule,
    MatMenuModule,
    ValidarArquivoModule,
    FileUploadModule,
    // QuillModule.forRoot({
    //   placeholder: 'Digite aqui a descrição do voto',
    //   modules: {
    //     toolbar:[
    //       ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    //       ['blockquote', 'code-block'],
    //       [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //       [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    //       [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    //       [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    //       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    //       [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //       [{ 'font': [] }],
    //       [{ 'align': [] }],
    //       ['clean'],                                         // remove formatting button
    //       ['link']                         // link and image, video
    //     ]
    //   }
    // }),
    MatRadioModule,
  ],
  declarations: [
    ProcessoDetalhadoComponent,
    OrdenarDocumentosComponent,
    VisualizarArquivoComponent,
    RetificarProcessoComponent,
    VotoComponent, 
  ],
  exports: [
    ProcessoDetalhadoComponent
  ],
  providers: [
    LoginService,
    ProcessoDetalhadoService,
  ]
})
export class ProcessoDetalhadoModule { }
