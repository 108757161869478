import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { Eventos } from 'src/app/shared/models/eventos.model';
import { ProcessoDetalhado } from 'src/app/shared/models/processo-detalhado.model';
import { Tramitacao } from 'src/app/shared/models/tramitacao.model';
import { BodyFiltro, ResponseFiltro } from 'src/app/shared/models/filtro.model';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('engine:engine')
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConsultarProcessoService {
  idGrupo: number;
  idUsuario: number;
  usuarioData: UsuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idGrupo = this.usuarioData.modulo.idGrupo;
    this.idUsuario = this.usuarioData.idUsuario;
  }

  calculatePageNumber(skip: number, take: number) {
    return skip / take
  }

  listarEventos(take: number, skip: number, idProcesso: number) {
    const pageNumber = this.calculatePageNumber(skip, take)
    return this.http.get<Eventos>(environment.caminhoApi +
      `eventos?idProcesso=${idProcesso}&qtdItensPorPagina=${take}&pagina=${pageNumber}`,
      httpOptions);
  }

  detalharProcesso(idProcesso: number) {
    return this.http.get<ProcessoDetalhado>(environment.caminhoApi +
      `detalhar-processo?idProcesso=${idProcesso}&idUsuario=${this.idUsuario}`,
      httpOptions);
  }

  tramitarProcesso(tramitar) {
    return this.http.post<Tramitacao>(environment.caminhoApi +
      `tramitar`, tramitar, httpOptions);
  }

  listarProcessos(pag: number, quantidade: number, from: string, to: string, filter: string, input: string) {
    filter === "" ? filter = "" : filter = `&filter=${filter}`;
    input === "" ? input = "" : input = `&input=${input}`;
    if(filter === 'num_protocolo') {
      from = '0000-00-00';
      to = '0000-00-00';
    }
    return this.http
    .get(`${environment.caminhoApi}processos/historico/?pag=${pag}&qtd=${quantidade}&from=${from}&to=${to}${filter}${input}`, httpOptions);
  }

  listarUsuariosBusca(idUsuario) {
    return this.http.get(`${environment.apiAdmin}usuarios-modulo?idModulo=${idUsuario}`, httpOptions);
  }

  filtro(filtro: BodyFiltro):Observable<ResponseFiltro>{
    return this.http.post<ResponseFiltro>(`${environment.caminhoApi}historico`,filtro, httpOptions);
  }

}
