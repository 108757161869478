import { JuntarProcessoComponent } from './../components/juntar-processo/juntar-processo.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JuntarProcessoService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(processosSelecionados){
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(JuntarProcessoComponent, {data: processosSelecionados})
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed()
      .subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }
}
