import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { environment } from 'src/environments/environment';
import { DocumentTemplate } from '../model/document-template.model';
import { DocumentTemplateConfig } from '../model/document-template-config.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateService {

  idUsuario: number;
    idModulo: number;
    usuarioData: UsuarioData;

    private apiAdminURL = `${environment.apiAdmin}document-template`;

    constructor(private http: HttpClient) {
      this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
      this.idUsuario = this.usuarioData.idUsuario;
      this.idModulo = this.usuarioData.modulo.id;
    }

    getAll(moduloId: number):Observable<DocumentTemplate[]> {
      console.log(moduloId)
      return this.http.get<DocumentTemplate[]>(`${this.apiAdminURL}?moduloId=${moduloId}`, httpOptions);
    }

    getOne(id: number):Observable<DocumentTemplate> {
      return this.http.get<DocumentTemplate>(`${this.apiAdminURL}/${id}`, httpOptions);
    }

    save(documentTemplate: DocumentTemplate):Observable<DocumentTemplate> {
      return this.http.post<DocumentTemplate>(`${this.apiAdminURL}?idUsuarioLogado=${this.idUsuario}`, documentTemplate, httpOptions);
    }

    disable(id: number) {
      return this.http.patch(`${this.apiAdminURL}/disable/${id}?idUsuarioLogado=${this.idUsuario}`, httpOptions);
    }

    saveConfig(config: DocumentTemplateConfig):Observable<DocumentTemplateConfig> {
      return this.http.post<DocumentTemplateConfig>(`${this.apiAdminURL}/config?idUsuarioLogado=${this.idUsuario}`, config, httpOptions);
    }

    getConfig(id: number):Observable<DocumentTemplateConfig> {
      return this.http.get<DocumentTemplateConfig>(`${this.apiAdminURL}/config/${id}`, httpOptions);
    }

}
