import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredenciaisService } from 'src/app/shared/services/credenciais.service';
import { environment } from 'src/environments/environment';

import { Distribuir, DistribuirSugestoes, DistribuirParams } from './../../../models/distribuir';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa('engine:engine')
  })
};

@Injectable({
  providedIn: 'root'
})
export class DistribuirService {

  constructor( private http: HttpClient, private credenciais: CredenciaisService ) {
  }

  distribuirParams(distribuirParams: DistribuirParams) {
    distribuirParams.idModulo = this.credenciais.idModulo();
    distribuirParams.idUsuario = this.credenciais.idUsuario();

    return this.http.post<DistribuirSugestoes>(`${environment.caminhoApi}distribuir/params`, distribuirParams, httpOptions);
  }

  distribuir(distribuir: Distribuir) {
    distribuir.idModulo = this.credenciais.idModulo();
    distribuir.idUsuario = this.credenciais.idUsuario();

    return this.http.put<Boolean>(`${environment.caminhoApi}distribuir`, distribuir, httpOptions);
  }

}
