import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable()
export class ValidarArquivoService {

  constructor(
    private http: HttpClient,
    ) { }

  // Validar Arquivo
  validarArquivo(bodyMarcador){
    return this.http.put(`${environment.caminhoApi}validar-arquivo`, bodyMarcador, httpOptions);
  }

  // Excluir Arquivo
  invalidarArquivo(bodyMarcador){
    return this.http.put(`${environment.caminhoApi}invalidar-arquivo`, bodyMarcador, httpOptions);
  }

}