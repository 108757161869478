import { SolicitarAutenticacaoService } from './../../services/solicitar-autenticacao.service';
import { ProcessoDetalhadoService } from 'src/app/shared/services/processo-detalhado.service';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ProcessoSelecionado } from "src/app/shared/models/processo-selecionado.model";
import { JuntarService } from './services/juntar.service';

import { throwError } from 'rxjs';


@Component({
  selector: "app-juntar-processo",
  templateUrl: "./juntar-processo.component.html",
  styleUrls: ["./juntar-processo.component.css"]
})
export class JuntarProcessoComponent {

  public idsProcessos: Number[] = [];
  public selectedPrincipalProcesso: ProcessoSelecionado;
  public justificativaLabel: string;

  public displayedColumns: string[] = ["select", "protocolo", "name", "numeroAit"];
  public carregandoDistribuir: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<JuntarProcessoComponent>,
    private detalharProcesso: ProcessoDetalhadoService,
    public _snackBar: MatSnackBar,
    private _juntarService: JuntarService,
    public _autenticacaoService: SolicitarAutenticacaoService,
    @Inject(MAT_DIALOG_DATA) public selectedProcessos: Array<ProcessoSelecionado>
  ) { }

  ngOnInit(): void { }

  //Detalhar
  public detalharProcessoSelecionado = (selectedProcesso) => { this.detalharProcesso.abrirDialogo(selectedProcesso, false).subscribe() }

  //Funções do 'selectedPrincipalProcesso'
  public getPrincipalProcesso = (): ProcessoSelecionado => { return this.selectedPrincipalProcesso }
  public setPrincipalProcesso = (newSelectedPrincipalProcesso: ProcessoSelecionado) => { this.selectedPrincipalProcesso = newSelectedPrincipalProcesso }
  public isPrincipalProcesso = (newSelectedPrincipalProcesso: ProcessoSelecionado): boolean => { return this.selectedPrincipalProcesso === newSelectedPrincipalProcesso }

  public juntarProcessos(): void {
    try {
      if (!this.getPrincipalProcesso()) throw new Error("Selecione um Processo Principal !");
      if (!this.justificativaLabel) throw new Error("Justificativa não pode ser vazia !");

      this.snackBarNotification("Juntado Processos...", 'warn');
      this.carregandoDistribuir = true;

      const principalProcesso: ProcessoSelecionado = this.getPrincipalProcesso();
      let _selectedProcessoIds: Number[] = [];

      this.selectedProcessos.map((processo) => {
        if(processo.id != principalProcesso.id) _selectedProcessoIds.push(processo.id);
      })

      //Resquest '/distribuir/params'; params: { 'idsProcesso' }
      this._autenticacaoService.abrirDialogo().subscribe(
        resposta => {
          if (resposta) {
            this._juntarService.juntarProcessos({
              "descricao": this.justificativaLabel,
              "idProcessoPai": principalProcesso.id,
              "idsProcesso": _selectedProcessoIds
            }).subscribe(
              res => {
                this.snackBarNotification("Processos juntos com Sucesso !", 'sucesso');
                this.dialogRef.close(false);
              },
              error => {
                this.snackBarNotification(error.error.message, 'erro');
                console.error(error.error);
              });
            this.carregandoDistribuir = false;
          } else {
            this.carregandoDistribuir = false;
            // throw new Error("Falha ao autenticar o usuário !")
            this.snackBarNotification("Falha ao autenticar o usuário !", 'erro');
            console.error("Falha ao autenticar o usuário !");
          }
        });
    }
    catch (error) {
      console.error(error);
      this.snackBarNotification(error.message, 'erro'); }
  }


  //SnackBarNotification
  private snackBarNotification(msg: string, tipo: 'sucesso' | 'erro' | 'warn') {
    const config: MatSnackBarConfig = {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    }

    this._snackBar.open(msg, '', config);
  }
}
