import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from './models/login';
import { environment } from 'src/environments/environment';
import { VersionService } from 'src/app/shared/services/version.service';
import { LoginService } from './service/login-provider';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent {

  ngOnInit() {
    // Caso já esteja logado, vá para a página principal
    if (localStorage.getItem('usuarioData')) {
      this.router.navigate(['painel']);
    }
  }

  usuarioLogado: boolean = false; // Defina um valor inicial ou atribua dinamicamente


  //#region Variáveis
  senhaVisivel: boolean = false;

  loginModel: Login;
  login;
  senha;

  mensagemErroAutenticacao: string = null;
  version = this.versionService.version;
  ambiente = environment.ambiente;
  logoProduto = environment.logoProduto;
  logoCliente = environment.logoCliente;

  carregandoLogin: boolean;
  carregandoRedefinirSenha: boolean;
  userRedefinirSenha: string;
  redefinirSenha = false;

  //#endregion

  constructor(
    private router: Router,
    private loginProvider: LoginService,
    private versionService: VersionService,
    public _snackBar: MatSnackBar
  ) { }

  snackBarNotification(mensagem, tipo){
    this._snackBar.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  public redefinirSenhaUsuario() {
    if (
      this.userRedefinirSenha != null &&
      this.userRedefinirSenha !== ''
    ) {
      this.carregandoRedefinirSenha = true;
      this.loginProvider.redefinirSenha(this.userRedefinirSenha)
        .subscribe(
          res => {
            this.carregandoRedefinirSenha = false;
            this.userRedefinirSenha = '';
            this.redefinirSenha = false;
            this.snackBarNotification('A redefinição de senha foi enviada para o e-mail', 'sucesso')
          },
          error => {
            this.carregandoRedefinirSenha = false;
            this.snackBarNotification(error.error.message, 'erro')
          }
        )

    }
  }

  public realizarLogin() {
    if (
      this.login != null &&
      this.senha != null &&
      this.login !== '' &&
      this.senha !== ''
    ) {
      this.carregandoLogin = true;

      this.loginModel = new Login();
      this.loginModel.login = this.login;
      this.loginModel.senha = this.senha;
      this.loginModel.idSistema = 2;

      this.loginProvider.autenticarUsuario(this.loginModel).subscribe(
        res => {
          this.carregandoLogin = false;
          this.mensagemErroAutenticacao = null;
          const usuarioData = JSON.stringify(res); // Serializa a res para Json
          localStorage.setItem('usuarioData', usuarioData); // Salva a res serializada no localStorage
          this.router.navigate(['painel']);
          this.loginProvider.usuarioAutenticado();
        },
        err => {
          this.carregandoLogin = false;
          if (err.name === 'TimeoutError') {
            this.mensagemErroAutenticacao =
              'Erro no servidor! Favor atualizar a aplicação e tentar novamente. Se persistir, favor contactar a empresa responsável.';
          } else {
            this.mensagemErroAutenticacao = err.error.message;
          }
          this.snackBarNotification(err.error.message, 'erro')
        }
      );
    }
  }
}
