import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-panel-indicator',
  templateUrl: './panel-indicator.component.html',
  styleUrls: ['./panel-indicator.component.css']
})
export class PanelIndicatorComponent {

  @Input('icon-name') iconName: string
  @Input('icon-color') iconColor: string
  @Input() label: string
  @Input() value: number

}
