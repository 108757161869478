import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ProcessoDetalhadoService } from "./services/processo-detalhado.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { CadastrarArquivoService } from "src/app/shared/services/cadastrar-arquivo.service";
import { MatDialog } from "@angular/material/dialog";
import { OrdenarDocumentosComponent } from "./ordenar-documentos/ordenar-documentos.component";
import { Observable, Observer } from "rxjs";
import { VisualizarArquivoComponent } from "./visualizar-arquivo/visualizar-arquivo.component";
import { RetificarProcessoComponent } from "./retificar-processo/retificar-processo.component";
import { ValidarArquivoService } from "src/app/shared/services/validar-arquivo.service";
import { VotoComponent } from "./voto/voto.component";
import { GerarDocumentoService } from "../../services/gerar-documento.service";

@Component({
  selector: "app-processo-detalhado",
  templateUrl: "./processo-detalhado.component.html",
  styleUrls: ["./processo-detalhado.component.css"],
})
export class ProcessoDetalhadoComponent {
  estagiario =
    JSON.parse(localStorage.getItem("usuarioData")).tipoUsuario ===
    "ESTAGIARIO"; /** regra para estagiario **/
  carregamentoDados: boolean = true;
  carregamentoInteressado: boolean = true;
  carregamentoDocumentos: boolean = true;
  carregamentoEventos: boolean = true;
  // carregamentoVotos: boolean = false;
  dadosProcesso;
  acoesProcesso;
  dadosCarregados: boolean = false;
  permissoesAcoesCarregados: boolean = false;
  usuarioData = JSON.parse(localStorage.getItem("usuarioData"));
  carregandoTodosArquivos = false;
  todosArquivosBaixado = false;
  todosArquivoBase64;
  atualizarListaDeProcessos = false;

  @ViewChild("eventosPaginacao", { static: true })
  eventosPaginacao: MatPaginator;
  eventos = new MatTableDataSource();
  colunasEvento: string[] = [
    "evento",
    "dataOrigem",
    "responsavelOrigem",
    "responsavelDestino",
    "descricao",
  ];

  // @ViewChild('votosPaginacao', { static: true }) votosPaginacao: MatPaginator;
  // votos = new MatTableDataSource();
  // colunasVoto: string[] = [
  //   'dataVoto',
  //   'moduloVoto',
  //   'votoEnum',
  // ];

  @ViewChild("arquivosPaginacao", { static: true })
  arquivosPaginacao: MatPaginator;
  arquivos = new MatTableDataSource();
  colunasArquivos: string[] = [
    "nomeArquivo",
    "dataOrigem",
    "alocado",
    //'tipoDocumento',
    // 'evento',
    // 'origem',
    // 'responsavelOrigem',
    "descricao",
    "validar",
    "excluir",
    "arquivo",
  ];

  constructor(
    public dialogRef: MatDialogRef<ProcessoDetalhadoComponent>,
    private dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public processoService: ProcessoDetalhadoService,
    @Inject(MAT_DIALOG_DATA) public processo,
    public cadastrarArquivo: CadastrarArquivoService,
    public validarArquivoService: ValidarArquivoService,
    public gerarDocumento: GerarDocumentoService
  ) { }

  ngOnInit(): void {
    this.detalharProcesso();
    this.eventosProcesso();
    this.arquivosProcesso();
    this.acoesProcessoPermissoes();
    // this.listarVotosProcesso();
    this.eventos.paginator = this.eventosPaginacao;
    this.arquivos.paginator = this.arquivosPaginacao;
    if (!this.processo.acoes) {
      this.colunasArquivos.splice(this.colunasArquivos.indexOf("excluir"), 1);
      this.colunasArquivos.splice(this.colunasArquivos.indexOf("validar"), 1);
    }


  }

  detalharProcesso() {
    this.carregamentoDados = true;
    this.carregamentoInteressado = true;
    this.processoService.detalharProcesso(this.processo.idProcesso).subscribe(
      (res) => {
        //console.log(res)
        this.dadosProcesso = res;
        this.carregamentoDados = false;
        this.carregamentoInteressado = false;
        this.dadosCarregados = true;
      },
      (erro) => {
        //console.log(erro)
      }
    );
  }

  eventosProcesso() {
    this.carregamentoEventos = true;
    this.processoService.listarEventos(this.processo.idProcesso).subscribe(
      (res: any) => {
        //console.table(res)
        this.eventos.data = res;
        this.carregamentoEventos = false;
      },
      (erro) => {
        //console.log(erro)
      }
    );
  }

  arquivosProcesso() {
    this.carregamentoDocumentos = true;
    this.processoService.listarArquivos(this.processo.idProcesso).subscribe(
      (res: any) => {
        // console.table(res)
        this.arquivos.data = res;
        this.carregamentoDocumentos = false;
        console.log(res);
      },
      (erro) => {
        // console.log(erro)
      }
    );
  }

  abrirCadastroArquivo(idProcesso) {
    this.cadastrarArquivo.abrirDialogo(idProcesso).subscribe((res) => {
      if (res) {
        this.arquivosProcesso();
      }
      // console.log(res)
    });
  }

  abrirDocumentTemplate(idProcesso) {
    this.gerarDocumento.abrirDialogo(idProcesso).subscribe((res) => {
      if (res) {
        this.arquivosProcesso();
        this.eventosProcesso();
      }
      // console.log(res)
    });
  }

  // Método que da um find no array de funcionalidades para exibir ou não os botões.
  mostrarBotao(funcionalidade) {
    return this.usuarioData.modulo.funcionalidades.find(f => f.identificador === funcionalidade);
  }

  mostrarFuncionalidades(funcionalidade) {
    if (this.usuarioData) {
      //console.log(this.usuarioData.modulo);
      return this.usuarioData.modulo.funcionalidades.find(
        (f) => f.identificador === funcionalidade
      );
    }
  }

  acoesProcessoPermissoes() {
    this.processoService.acoesProcesso(this.processo.idProcesso).subscribe(
      (res: any) => {
        //console.log(res)
        this.acoesProcesso = res;
        this.permissoesAcoesCarregados = true;
      },
      (erro) => {
        //console.log(erro)
      }
    );
  }

  abrirOrdenacaoArquivos() {
    console.log("[abrirOrdenacaoArquivos]");
    console.log(this.processo.idProcesso);

    const dialogRef = this.dialog.open(OrdenarDocumentosComponent, {
      data: [this.arquivos.data, this.processo.idProcesso],
      disableClose: true,
      autoFocus: false,
      panelClass: "full-screen",
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "95%",
      width: "95%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.carregandoTodosArquivos = false;
        this.todosArquivosBaixado = false;
        this.arquivosProcesso();
      }
    });
  }

  pdfBase64toBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      observer.next(blob);
      observer.complete();
    });
  }

  createBlobPdfFileAndShow(arquivo): void {
    this.pdfBase64toBlob(arquivo).subscribe((blob: Blob) => {
      //console.log(blob)
      const pdfBlob: Blob = blob;
      const pdfName: string = "Teste.pdf";
      const pdfFile: File = new File([pdfBlob], pdfName, {
        type: "application/pdf",
      });
      window.open(window.URL.createObjectURL(pdfFile));
    });
  }

  todosArquivos() {
    if (!this.todosArquivosBaixado) {
      this.carregandoTodosArquivos = true;
      this.processoService
        .arquivosUnidos(this.processo.idProcesso)
        .subscribe((res: any) => {
          this.todosArquivoBase64 = res.base64;
          this.todosArquivosBaixado = true;
          this.carregandoTodosArquivos = false;
          // this.createBlobPdfFileAndShow(res.base64);
        });
    } else {
      this.createBlobPdfFileAndShow(this.todosArquivoBase64);
    }
  }

  visualizarArquivos(id, nome) {
    const dialogRef = this.dialog.open(VisualizarArquivoComponent, {
      data: { id, nome },
      height: "90vh",
      width: "90vw",
      panelClass: "visualizar-documento",
    });
  }

  abrirRetificacao() {
    const dialogRef = this.dialog.open(RetificarProcessoComponent, {
      data: this.processo.idProcesso,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.detalharProcesso();
        this.eventosProcesso();
        this.atualizarListaDeProcessos = true;
      }
    });
  }

  /*restaurarArquivo(id, nome) {
    this.validarArquivoService.abrirValidarArquivo({
      id,
      nome
    }).subscribe(res => {
      if (res === true) {
        this.carregandoTodosArquivos = false;
        this.todosArquivosBaixado = false;
        this.arquivosProcesso();
        this.eventosProcesso();
      }
    })
  }*/

  validarArquivo(id, nome, button: number) {
    if (button == 0) {
      this.validarArquivoService
        .abrirValidarArquivo({
          id,
          nome,
        })
        .subscribe((res) => {
          if (res === true) {
            this.carregandoTodosArquivos = false;
            this.todosArquivosBaixado = false;
            this.arquivosProcesso();
            this.eventosProcesso();
          }
        });
    }
  }

  excluirArquivo(id, nome) {
    this.validarArquivoService
      .abrirInvalidarArquivo({
        id,
        nome,
      })
      .subscribe((res) => {
        if (res === true) {
          this.carregandoTodosArquivos = false;
          this.todosArquivosBaixado = false;
          this.arquivosProcesso();
          this.eventosProcesso();
        }
      });
  }

  snackBarNotification(mensagem, tipo) {
    this._snackBar.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  abrirVoto() {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    //console.log(this.dadosProcesso.usuario);
    //console.log(this.usuarioData.nome);
    if (this.dadosProcesso.usuario !== this.usuarioData.nome) {
      this.snackBarNotification("Processo não alocado para seu usuário", 'erro');
    } else {
      const dialogRef = this.dialog.open(VotoComponent, {
        data: this.processo.idProcesso,
        disableClose: true,
        autoFocus: false,
        maxWidth: "100vw",
        maxHeight: "100vh",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.carregandoTodosArquivos = false;
          this.todosArquivosBaixado = false;
          this.arquivosProcesso();
          this.eventosProcesso();
          // this.listarVotosProcesso();
        }
      });
    }
  }

  // listarVotosProcesso(){
  //   this.carregamentoVotos = true;
  //   this.processoService.listarVotos(this.processo.idProcesso)
  //   .subscribe((res:any) =>{
  //     //console.table(res)
  //     this.votos.data = res;
  //     this.carregamentoVotos = false;
  //   },
  //   erro=>{
  //     //console.log(erro)
  //   })
  // }
}
