import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DocumentTemplateService } from './service/document-template.service';
import { DocumentTemplate } from './model/document-template.model';
import { DocumentTemplateFormComponent } from './document-template-form/document-template-form.component';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { UsuarioData } from 'src/app/shared/models/usuarioData.model';
import { DocumentTemplateConfigComponent } from './document-template-config/document-template-config.component';


@Component({
  selector: 'app-document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.css']
})
export class DocumentTemplateComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginacao: MatPaginator;

  usuarioData: UsuarioData;
  // Variáveis responsáveis por ligar o carregamento da pagina
  carregamentoTabela = false;
  colunas: string[] = [
    'id',
    'name',
    'status',
    'edit',
    'duplicar',
    'inativar'
  ];
  listDocuments = new MatTableDataSource();

  documentTemplates: DocumentTemplate[] = [];

  constructor(
    private documentTemplateService: DocumentTemplateService,
    private notificacao: MatSnackBar,
    public dialog: MatDialog,
    private autenticacao: SolicitarAutenticacaoService,
    // private dialogRef: MatDialogRef<DocumentTemplateComponent>,
  ) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  }

  ngOnInit(): void {
    this.listarDocumentos(this.usuarioData.modulo.id);
  }

  listarDocumentos(moduloId: number): void {
    this.carregamentoTabela = true;
    this.documentTemplateService.getAll(moduloId).subscribe(result => {
      this.carregamentoTabela = false;
      this.documentTemplates = result;
      this.listDocuments.data = result;
    }, (error) => {
      this.carregamentoTabela = false;
      this.snackBarNotification('Erro ao carregar Templates', 'erro');
      console.log(error)
    })
  }

  busca(value: string) {
    this.listDocuments.filter = value.trim().toLocaleLowerCase();
  }

  cadastrarDocumentTemplate() {
    const dialogRef = this.dialog.open(DocumentTemplateFormComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarDocumentos(this.usuarioData.modulo.id);
      }
    });
  }

  configDocumentTemplate () {
    const dialogRef = this.dialog.open(DocumentTemplateConfigComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarDocumentos(this.usuarioData.modulo.id);
      }
    });
  }

  editarDocumentTemplate(documento: DocumentTemplate) {
    const documentoCopia = { ...documento };
    documentoCopia.action = 'editar'
    const dialogRef = this.dialog.open(DocumentTemplateFormComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
      data: documentoCopia
    });

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarDocumentos(this.usuarioData.modulo.id);
      }
    });
  }

  duplicarDocumentTemplate(documento: DocumentTemplate) {
    const documentoCopia = { ...documento };
    documentoCopia.action = 'duplicar'
    const dialogRef = this.dialog.open(DocumentTemplateFormComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '95%',
      width: '95%',
      autoFocus: false,
      data: documentoCopia
    });

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarDocumentos(this.usuarioData.modulo.id);
      }
    });
  }

  disableDocumentTemplate(documento: DocumentTemplate) {
    this.autenticacao.abrirDialogo().subscribe(response => {
      if (response) {

        this.documentTemplateService.disable(documento.id).subscribe(result => {
          this.snackBarNotification('Template de Documento desabilitado com sucesso!', 'sucesso');
          this.listarDocumentos(this.usuarioData.modulo.id);
        },
          err => {
            this.snackBarNotification(err.error.message, 'erro');
          })
      }
    })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
