import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtualizarSenhaComponent } from './atualizar-senha.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { AtualizarSenhaService } from './service/atualizar-senha.service';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AtualizarSenhaComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  // declarations: [
  //   AtualizarSenhaComponent, 
  // ],
  exports: [
    AtualizarSenhaComponent
  ],
  providers: [
    AtualizarSenhaService,
  ]
})
export class AtualizarSenhaModule { }
