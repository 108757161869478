import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { VincularAitService } from '../services/vincular-ait.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-selecionar-processo',
  templateUrl: './selecionar-processo.component.html',
  styleUrls: ['./selecionar-processo.component.css']
})
export class SelecionarProcessoComponent implements OnInit {

  constructor(
    private processosSemAit: VincularAitService,
    private notificacao: MatSnackBar,
    public dialogRef: MatDialogRef<SelecionarProcessoComponent>,
  ) { }

  displayedColumns: string[] = [
    'select',
    'numeroProtocolo',
    'numeroAit',
    'nomeInteressado',
    'tipoPessoaEnum',
    'tipoProcessoEnum',
    'nomeCondutor',
    'documento',
    'placa',
  ];
  pageSize = 10;
  pageIndex = 1;
  totalProcessos = 0;
  dataInicio = new FormControl(new Date());
  dataFim = new FormControl(new Date());
  input = "";
  filtro = "";
  listFiltro = [
    {value: "", nome: '-- Nenhum --'},
    {value: "tipo", nome: 'Tipo'},
    {value: "status", nome: 'Status'},
    {value: "num_protocolo", nome: 'Número Protocolo'},
    {value: "origem", nome: 'Origem'},
    // {value: "alocado", nome: 'Alocado'},
  ]
  listStatus = [
    {value: "0", nome: "Aberto"},
    {value: "1", nome: "Andamento"},
    {value: "2", nome: "Arquivado"},
    {value: "3", nome: "Deferido"},
    {value: "4", nome: "Indeferido"},
    {value: "5", nome: "Cancelado"},
    {value: "6", nome: "Suspenso"},
    {value: "7", nome: "Descartado"},
  ]
  listTipos = [
    {value: "0", nome: "Defesa de Autuação"},
    {value: "1", nome: "Recurso"},
    {value: "2", nome: "Comunicação de Venda"},
    {value: "3", nome: "Transferência  de Pontuação"},
  ]
  listSetores = [
    {value: "1", nome: "Protocolo"},
    {value: "2", nome: "Renainf"},
    {value: "3", nome: "Projur"},
    {value: "4", nome: "Jari"},
    {value: "9999", nome: "Portal Cidadão"},
  ]
  listUsuarios = [];
  processosListados = new MatTableDataSource();
  isLoadingResults = false;
  selection = new SelectionModel(false);


  pipe = new DatePipe('en-US');

  ngOnInit() {
    this.dataInicio.value.setDate(this.dataInicio.value.getDate()-30);
    this.listarProcesso();
  }

  transformarData(data: Date) {
    return this.pipe.transform(data, 'yyyy-MM-dd')
  }

  listarProcesso() {

    this.isLoadingResults = true;
    this.selection.clear() 
    this.processosSemAit.listarProcessos(this.pageIndex, this.pageSize, this.transformarData(this.dataInicio.value), this.transformarData(this.dataFim.value), this.filtro, this.input)
      .subscribe((res: any) => {
        this.isLoadingResults = false;
        this.processosListados.data = res.itens;
        this.totalProcessos = res.qtd;
        console.log(res);
      }, err =>{
        this.isLoadingResults = false;
        this.snackBarNotification(err.error.message, 'erro');
        console.warn(err);
      })

  }

  aitSelecionada(){
    this.dialogRef.close(this.selection.selected[0])
  }

  // filtro(value: string){
  //   this.processosListados.filter = value.trim().toLocaleLowerCase();
  // }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  pageEvent(event) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.listarProcesso();
  }

}
