import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioData } from '../models/usuarioData.model';
import { environment } from 'src/environments/environment';
import { MandatoryFields } from '../models/mandatoty-fields';
import { Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('engine:engine')
    })
  };

  @Injectable({
    providedIn: 'root'
  })
  export class MandatotyFieldsService {
    idUsuario: number;
    idModulo: number;
    usuarioData: UsuarioData;

    constructor(private http: HttpClient) {
      this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
      this.idUsuario = this.usuarioData.idUsuario;
      this.idModulo = this.usuarioData.modulo.id;
    }

    listarCampos():Observable<MandatoryFields[]> {
        return this.http.get<MandatoryFields[]>(`${environment.apiAdmin}mandatory-fields`, httpOptions);
    }

}